<script setup lang="ts">
import type { PropType } from 'vue'
import { computed } from 'vue'

import type { StyledSegment } from '@/lib/types/tower-svg'
import { useTowerStore } from '@/stores/towers'

import type { LayerModel } from '@/capability/layer/LayerModel'
import { getStyledLayers } from '@/capability/layer/utils'

import LayerDiagram from '@/component/layer/LayerDiagram.vue'

const props = defineProps({
  rawSegments: {
    type: Array as PropType<LayerModel[]>,
    required: true
  },
  layerId: {
    type: String,
    required: true
  },
  layerName: {
    type: String,
    required: true
  },
  layerMax: {
    type: Number,
    required: true
  },
  layerMin: {
    type: Number,
    required: true
  },
  level: {
    type: Number,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'selectLayer', payload: boolean): void
}>()

const towerStore = useTowerStore()

const segments = computed((): StyledSegment[] => {
  let left = 0
  return props.rawSegments!.map((segment: LayerModel) => {
    const style = {
      left: `${left}%`,
      width: `${segment.percentage}%`
    }
    left += segment.percentage ?? 0
    return { segment, style }
  })
})

const scrollToSegment = (id: string): void => {
  towerStore.$patch({ activeTowerId: props.layerId, activeLayerId: id })
  emit('selectLayer', false)
  const element = document.getElementById(`segment-group~~${id}`)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

const styledLayers = (layers: LayerModel[]) => getStyledLayers(layers, props.layerMax - props.layerMin)
</script>

<template>
  <div
    v-for="{ segment, style } in segments"
    :key="segment.id"
    class="absolute box-border flex h-full p-[1px]"
    :style="style"
    @click.stop="scrollToSegment(segment.id as string)"
  >
    <div id="layers-segment">
      <LayerDiagram
        v-for="layer in styledLayers(segment.layers as LayerModel[])"
        :key="layer.layer.id"
        :layer="layer"
        :tower-id="layerId"
        :tower-name="layerName"
        :can-interact="false"
        :level="level + 1"
      />
    </div>
    <div class="z-[999] h-full w-full border border-dashed border-white"></div>
    <div
      v-if="level < 2"
      class="absolute whitespace-nowrap"
      :class="level === 1 ? 'font-base right-[3px] top-0 text-xs italic' : 'bottom-0 left-[3px]'"
    >
      {{ segment.percentage }}%
    </div>
    <rq-tooltip :text="`${segment.percentage}%: ${segment.getNameWithDefault()}`" :options="{ placement: 'bottom-start', arrow: false }" />
  </div>
</template>

<style scoped lang="scss"></style>
