<script setup lang="ts">
import type { PropType } from 'vue'
import { computed } from 'vue'

type ScaleHeightsType = {
  height: number
  value: number
}

const props = defineProps({ scaleHeights: { type: Array as PropType<ScaleHeightsType[]>, required: true } })

const ticks = computed(() => [
  { style: { top: '100%' }, value: 0 },
  ...props.scaleHeights.map((e: ScaleHeightsType) => ({
    style: { top: `${(1 - e.height) * 100}%` },
    value: Intl.NumberFormat('en', { notation: 'compact' }).format(e.value)
  }))
])
</script>

<template>
  <div id="scale">
    <div v-for="(tick, idx) in ticks" :key="`tick~${idx}`" :style="tick.style" class="tick">
      {{ tick.value }}
    </div>
  </div>
</template>

<style scoped lang="scss">
#scale {
  min-width: 50px;
  height: calc(100% - 4px);
  border-left: 1px solid #000;
  font-size: 0.6rem;
  position: relative;
  .tick {
    position: absolute;
    margin-left: 15px;
    transform: translateY(-50%);
    &::before {
      content: '';
      position: absolute;
      width: 7px;
      height: 1px;
      background-color: black;
      left: -15px;
      top: 50%;
    }
  }
}
</style>
