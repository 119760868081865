import { plainToInstance } from 'class-transformer'
import cloneDeep from 'lodash.clonedeep'

import { DealModelImpl } from '@/capability/deal/DealModelImpl'
import type { DealModel } from '@/capability/deal/types/deal-model'
import type { DealModelConstructionAttr } from '@/capability/deal/types/deal-model-construction-attr'
import type { LayerModelConstructionAttr } from '@/capability/layer/LayerModelConstructionAttr'
import { LayerModelImpl } from '@/capability/layer/LayerModelImpl'
import type { LayerModelMutable } from '@/capability/layer/LayerModelMutable'
import type { ProgramMinimalModel } from '@/capability/program/ProgramMinimalModel'
import { ProgramMinimalModelImpl } from '@/capability/program/ProgramMinimalModelImpl'
import type { ProgramModel } from '@/capability/program/ProgramModel'
import type { ProgramModelConstructionAttr } from '@/capability/program/ProgramModelConstructionAttr'
import { ProgramModelImpl } from '@/capability/program/ProgramModelImpl'
import type { SegmentModelConstructionAttr } from '@/capability/segment/SegmentModelConstructionAttr'
import type { TowerModelConstructionAttr } from '@/capability/tower/TowerModelConstructionAttr'
import type { TowerModelImmutable } from '@/capability/tower/TowerModelImmutable'
import { TowerModelImpl } from '@/capability/tower/TowerModelImpl'
import type { TowerModelMutable } from '@/capability/tower/TowerModelMutable'
import { generateV4Uuid } from '@/capability/uuid/uuidUtil'
import type { DealDto, LayerDto, ProgramDto, ProgramMinimalDto, TowerDto } from 'typescript-core-api-client'

export interface ResourceModelFactory {
  buildTower: (attr: TowerModelConstructionAttr, superimposeDefaultValuesIfUndefined: boolean) => TowerModelMutable
  buildTowerFromDto: (towerDto: TowerDto, superimposeDefaultValuesIfUndefined: boolean) => TowerModelMutable
  buildArrayOfTowerModelFromDto: (towerDto: TowerDto[], superimposeDefaultValuesIfUndefined: boolean) => TowerModelMutable[]
  deepCloneTowerModel: (towerModelMutable: TowerModelImmutable) => TowerModelMutable

  buildSubLayerFromSubLayerDto: (subLayerDto: LayerDto, superimposeDefaultValuesIfUndefined: boolean) => LayerModelMutable
  buildSubLayer: (attr: LayerModelConstructionAttr, superimposeDefaultValuesIfUndefined: boolean) => LayerModelMutable
  buildSegment: (attr: SegmentModelConstructionAttr, superimposeDefaultValuesIfUndefined: boolean) => LayerModelMutable
  buildSegmentFromLayerDto: (subLayerDto: LayerDto, superimposeDefaultValuesIfUndefined: boolean) => LayerModelMutable

  buildLayer: (attr: LayerModelConstructionAttr, superimposeDefaultValuesIfUndefined: boolean) => LayerModelMutable
  buildLayerFromLayerDtoViaMoveSemantics: (layerDto: LayerDto, superimposeDefaultValuesIfUndefined: boolean) => LayerModelMutable

  buildDeal: (attr: DealModelConstructionAttr, superimposeDefaultValuesIfUndefined: boolean) => DealModel
  buildDealFromDtoViaMoveSemantics: (dealDto: DealDto, superimposeDefaultValuesIfUndefined: boolean) => DealModel

  buildProgram: (attr: ProgramModelConstructionAttr) => ProgramModel
  buildProgramFromDtoViaMoveSemantics: (programDto: ProgramDto) => ProgramModel
  buildProgramMinimal: (programMinimalDto: ProgramMinimalDto) => ProgramMinimalModel
  deepCloneProgramModel: (programModel: ProgramModel) => ProgramModel

  // if a visitor goes to /deal/new, what will be in the model?
  // TODO:  These probably should live on level of abstraction higher NewForClientResourceModelFactory?  (that's a mouthful)
  // buildNewForClientTower: (attr: TowerModelConstructionAttr) => TowerModelMutable
  // buildNewForClientLayer: (attr: LayerModelConstructionAttr) => LayerModelMutable
  // buildNewForClientDeal: (attr: DealModelConstructionAttr) => DealModel
  buildNewForClientProgram: (attr: ProgramModelConstructionAttr) => ProgramModel

  generateIdClientSide: () => string
}

export const resourceModelFactory = {
  buildTower: function (attr: TowerModelConstructionAttr, superimposeDefaultValuesIfUndefined: boolean): TowerModelMutable {
    return new TowerModelImpl(attr)
  },
  buildTowerFromDto: function (towerDto: TowerDto, superimposeDefaultValuesIfUndefined: boolean): TowerModelMutable {
    return new TowerModelImpl({ _towerDto: towerDto })
  },
  buildArrayOfTowerModelFromDto: function (arrayOfTowerDto: TowerDto[], superimposeDefaultValuesIfUndefined: boolean): TowerModelMutable[] {
    if (arrayOfTowerDto == null) {
      return arrayOfTowerDto as unknown as TowerModelMutable[]
    }

    return arrayOfTowerDto.map((towerDto) => this.buildTowerFromDto(towerDto, superimposeDefaultValuesIfUndefined))
  },
  deepCloneTowerModel(towerModelMutable: TowerModelImmutable): TowerModelMutable {
    return new TowerModelImpl(towerModelMutable.asJson() as unknown as TowerModelConstructionAttr)
  },
  buildLayer(attr: LayerModelConstructionAttr, superimposeDefaultValuesIfUndefined: boolean): LayerModelMutable {
    return new LayerModelImpl(attr)
  },
  buildLayerFromLayerDtoViaMoveSemantics(layerDto: LayerDto, superimposeDefaultValuesIfUndefined: boolean): LayerModelMutable {
    return new LayerModelImpl({ _layerDto: layerDto })
  },
  buildSubLayerFromSubLayerDto(subLayerDto: LayerDto, superimposeDefaultValuesIfUndefined: boolean): LayerModelMutable {
    return new LayerModelImpl({ _layerDto: subLayerDto })
  },
  buildSubLayer(attr: LayerModelConstructionAttr, superimposeDefaultValuesIfUndefined: boolean): LayerModelMutable {
    return new LayerModelImpl(attr)
  },
  buildSegment(attr: SegmentModelConstructionAttr, superimposeDefaultValuesIfUndefined: boolean): LayerModelMutable {
    return new LayerModelImpl(attr)
  },
  buildSegmentFromLayerDto(subLayerDto: LayerDto, superimposeDefaultValuesIfUndefined: boolean): LayerModelMutable {
    return new LayerModelImpl({ _layerDto: subLayerDto })
  },
  buildDeal(attr: DealModelConstructionAttr, superimposeDefaultValuesIfUndefined: boolean): DealModel {
    return new DealModelImpl(attr)
  },
  buildDealFromDtoViaMoveSemantics(dealDto: DealDto, superimposeDefaultValuesIfUndefined: boolean): DealModel {
    return new DealModelImpl({ dealDto: dealDto })
  },
  buildProgram(attr: ProgramModelConstructionAttr): ProgramModel {
    return new ProgramModelImpl(attr)
  },
  buildProgramFromDtoViaMoveSemantics(programDto: ProgramDto): ProgramModel {
    return plainToInstance(ProgramModelImpl, programDto)
  },
  buildProgramMinimal(programMinimalDto: ProgramMinimalDto): ProgramMinimalModel {
    return plainToInstance(ProgramMinimalModelImpl, programMinimalDto)
  },
  deepCloneProgramModel(programModel: ProgramModel): ProgramModel {
    return this.buildProgramFromDtoViaMoveSemantics(cloneDeep(programModel) as ProgramDto)
  },
  buildNewForClientProgram(attr: ProgramModelConstructionAttr): ProgramModel {
    return this.buildProgram(attr)
  },
  generateIdClientSide(): string {
    return generateV4Uuid()
  }
} as ResourceModelFactory
