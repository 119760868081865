<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { computed, useSlots } from 'vue'
import type { CheckboxRootEmits, CheckboxRootProps } from 'radix-vue'
import { CheckboxIndicator, CheckboxRoot, useForwardPropsEmits } from 'radix-vue'

import { cn } from '@/lib/utils'

const props = withDefaults(
  defineProps<
    CheckboxRootProps & {
      class?: HTMLAttributes['class']
      id?: HTMLAttributes['id']
      label?: string
      datacy?: string
      ariaLabel?: HTMLAttributes['aria-label']
    }
  >(),
  {
    class: '',
    id: '',
    label: '',
    datacy: '',
    ariaLabel: ''
  }
)
const emits = defineEmits<CheckboxRootEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)

const slots = useSlots()

const hasLabel = computed(() => !!slots.label || !!props.label)
</script>

<template>
  <div class="flex">
    <CheckboxRoot
      v-bind="forwarded"
      :class="
        cn(
          'peer h-4 w-4 shrink-0 rounded border border-gray-500 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary-500 data-[state=checked]:text-white',
          props.class ?? ''
        )
      "
    >
      <CheckboxIndicator class="flex h-full w-full items-center justify-center text-current">
        <slot>
          <rq-icon icon="mdi:check-bold" class="h-3.5 w-3.5" />
        </slot>
      </CheckboxIndicator>
    </CheckboxRoot>
    <slot v-if="hasLabel" name="label">
      <label :for="id" class="ml-3 text-sm font-medium leading-4 text-gray-900">{{ label }}</label>
    </slot>
  </div>
</template>
