export function getRiskAttributesRoutes(access?: 'Retailer' | 'Carrier') {
  const children = [
    {
      path: '',
      name: 'RiskAttributesBase',
      redirect: { name: 'RiskSnapshot' }
    },
    {
      path: 'risk-snapshot',
      alias: ['Risk%20Snapshot'],
      name: 'RiskSnapshot',
      component: () => import('@/views/risk-attributes/RiskAttributesRiskSnapshot.vue'),
      meta: {
        title: 'Risk Snapshot',
        pageViewDetails: {
          pageId: 'risk-attribute-risk-snapshot',
          resourceType: 'risk-attribute',
          resourceCrudl: 'risk-snapshot'
        },
        ruleName: 'deal-read-info', // todo(jeff): should I create custom rules for this functionality?
        requiresAuth: true
      }
    },
    {
      path: 'risk-narrative',
      alias: ['Risk%20Narrative'],
      name: 'RiskNarrative',
      component: () => import('@/views/risk-attributes/RiskAttributesRiskNarrative.vue'),
      meta: {
        title: 'Risk Narrative',
        pageViewDetails: {
          pageId: 'risk-attribute-risk-narrative',
          resourceType: 'risk-attribute',
          resourceCrudl: 'risk-narrative'
        },
        ruleName: 'deal-read-info', // todo(jeff): should I create custom rules for this functionality?
        requiresAuth: true
      }
    },
    {
      path: 'statement-of-values',
      alias: ['arqu%20SOV'],
      name: 'RiskStatementOfValues',
      component: () => import('@/views/risk-attributes/RiskAttributesStatementOfValues.vue'),
      meta: {
        title: 'Statement of Values',
        pageViewDetails: {
          pageId: 'risk-attribute-sov',
          resourceType: 'risk-attribute',
          resourceCrudl: 'sov'
        },
        ruleName: 'deal-read-info', // todo(jeff): should I create custom rules for this functionality?
        requiresAuth: true
      }
    },
    {
      path: 'loss-summary',
      alias: ['Loss%20Summary'],
      name: 'RiskLossSummary',
      component: () => import('@/views/risk-attributes/RiskAttributesLossSummary.vue'),
      meta: {
        title: 'Loss Summary',
        pageViewDetails: {
          pageId: 'risk-attribute-loss-summary',
          resourceType: 'risk-attribute',
          resourceCrudl: 'loss-summary'
        },
        ruleName: 'deal-read-info', // todo(jeff): should I create custom rules for this functionality?
        requiresAuth: true
      }
    },
    {
      path: 'loss-summary-spreadsheet',
      alias: ['Loss%20Summary%20Spreadsheet'],
      name: 'RiskLossSummarySpreadsheet',
      component: () => import('@/views/risk-attributes/RiskAttributesLossSummarySpreadsheet.vue'),
      meta: {
        title: 'Loss Summary Sheet',
        pageViewDetails: {
          pageId: 'risk-attribute-loss-summary-spreadsheet',
          resourceType: 'risk-attribute',
          resourceCrudl: 'loss-summary'
        },
        ruleName: 'deal-read-info',
        requiresAuth: true
      }
    },
    {
      path: 'schedule-summary',
      alias: ['Schedule%20Summary'],
      name: 'ScheduleSummary',
      component: () => import('@/views/risk-attributes/RiskAttributesScheduleSummary.vue'),
      meta: {
        title: 'Schedule Summary',
        pageViewDetails: {
          pageId: 'risk-attribute-schedule-summary',
          resourceType: 'risk-attribute',
          resourceCrudl: 'schedule-summary'
        },
        ruleName: 'deal-read-info', // todo(jeff): should I create custom rules for this functionality?
        requiresAuth: true
      }
    },
    {
      path: 'schedules',
      name: 'RiskSchedules',
      component: () => import('@/views/risk-attributes/RiskAttributesSchedules.vue'),
      meta: {
        title: 'Schedules',
        pageViewDetails: {
          pageId: 'risk-attribute-schedule',
          resourceType: 'risk-attribute',
          resourceCrudl: 'schedule'
        },
        ruleName: 'deal-read-info',
        requiresAuth: true
      }
    },
    {
      path: 'risk-additional-link',
      name: 'RiskAdditionalLink',
      component: () => import('@/views/risk-attributes/RiskAttributesAdditionalLink.vue'),
      meta: {
        title: 'Risk Additional link',
        pageViewDetails: {
          pageId: 'risk-attribute-additional-link',
          resourceType: 'risk-attribute',
          resourceCrudl: 'additional-link'
        },
        ruleName: 'deal-read-info',
        requiresAuth: true
      }
    },
    {
      path: 'loss-scenario',
      name: 'RiskLossScenario',
      component: () => import('@/views/risk-attributes/RiskAttributesLossScenario.vue'),
      meta: {
        title: 'Loss Scenario',
        pageViewDetails: {
          pageId: 'risk-attribute-loss-scenario',
          resourceType: 'risk-attribute',
          resourceCrudl: 'loss-scenario'
        },
        ruleName: 'deal-read-info',
        requiresAuth: true
      }
    },
    {
      path: 'gc-checker',
      alias: ['GC%20Checker'],
      name: 'RiskGcChecker',
      component: () => import('@/views/risk-attributes/RiskAttributesGeneralContractorChecker.vue'),
      meta: {
        title: 'General Contractor Checker',
        pageViewDetails: {
          pageId: 'risk-attribute-gc-checker',
          resourceType: 'risk-attribute',
          resourceCrudl: 'gc-checker'
        },
        ruleName: 'deal-read-info',
        requiresAuth: true
      }
    },
    {
      path: 'miner-checker',
      alias: ['Miner%20Checker'],
      name: 'RiskMinerChecker',
      component: () => import('@/views/risk-attributes/RiskAttributesMinerChecker.vue'),
      meta: {
        title: 'Miner Checker',
        pageViewDetails: {
          pageId: 'risk-attribute-miner-checker',
          resourceType: 'risk-attribute',
          resourceCrudl: 'miner-checker'
        },
        ruleName: 'deal-read-info',
        requiresAuth: true
      }
    },
    {
      path: 'hazard-hub',
      alias: ['hazardhub'],
      name: 'HazardHub',
      component: () => import('@/views/risk-attributes/RiskAttributesHazardHub.vue'),
      meta: {
        requiresAuth: true,
        pageViewDetails: {
          pageId: 'risk-attributes-hazard-hub',
          resourceType: 'risk-attributes-hazard-hub',
          resourceCrudl: 'read'
        }
      }
    }
  ].map((c) => ({ ...c, name: access ? `${c.name}-${access}` : c.name }))

  return {
    path: 'risk-attributes',
    alias: 'riskattributes',
    component: () => import('@/views/risk-attributes/TheRiskAttributes.vue'),
    beforeEnter: (to, from, next) => {
      const path = to.path.toLowerCase()
      if (path.includes('riskattributes')) {
        return next(path.replace('riskattributes', 'risk-attributes'))
      }
      next()
    },
    children
  }
}
