/**
 * d4l -> decorate for logging
 *
 * The output of this is for human consumption.  And subject to change at any time for any reason.
 * Do not base any deterministic logic on the out of this method
 */
import { safeStringify } from '@/capability/json/jsonUtil'
// Do NOT import eventUtil.ts or any other util for what matter

// decorate (anything) for logging (to show up in a log)
export function d4l(zz: any): string {
  if (typeof zz === 'undefined') {
    return '<undefined> (undefined)'
  }
  if (zz == null) {
    return '<null> (null)'
  }
  if (typeof zz === 'string') {
    return `'${zz}' (string)`
  }
  if (typeof zz === 'number') {
    return `${zz} (number)`
  }
  if (typeof zz === 'boolean') {
    return `${zz} (boolean)`
  }

  if (zz instanceof Promise) {
    return '<Promise>'
  }

  if (typeof zz === 'object') {
    if (Array.isArray(zz)) {
      if (zz.length >= 2) {
        return `[${d4l(zz[0])}, ..., ${d4l(zz[zz.length - 1])}] (array, length=${zz.length})`
      } else if (zz.length == 1) {
        return `[${d4l(zz[0])}] (array, length=${zz.length})`
      } else {
        return `[] (array, length=${zz.length})`
      }
    }

    if (typeof zz.toHuman === 'function') {
      try {
        return `${zz?.toHuman()} <toHumanable> (object)`
      } catch (err) {
        return `'${zz} (object) [could not invoke #toHuman()]`
      }
    }

    if (typeof zz.then === 'function') {
      return '<Thenable> (object)'
    }

    if (zz.target != null) {
      if (Object.prototype.hasOwnProperty.call(zz.target, 'value')) {
        // Ooooh.... perhaps an event?
        const event = zz

        const parts: Array<string> = []
        parts.push(`event.target.value = ${d4l(event.target.value)}`)
        parts.push(`event.type = ${d4l(event.type)}`)
        parts.push(`event.value = ${d4l(event.value)}`)
        parts.push(`event.data = ${d4l(event.data)}`)
        parts.push(`event.key = ${d4l(event.key)}`)

        return `${parts.join(',  ')} (object) [event-like]`
      }
    }

    try {
      return safeStringify(zz) + ' (object)'
    } catch (err) {
      return `'${zz} (object) [could not safeStringify]`
    }
  }

  return `${zz}`
}
