<script lang="ts">
export default {
  inheritAttrs: false
}
</script>

<script setup lang="ts">
import { computed, type HTMLAttributes } from 'vue'
import { ComboboxInput, type ComboboxInputProps, useForwardProps } from 'radix-vue'

import { cn } from '@/lib/utils'

defineOptions({
  inheritAttrs: false
})

const props = defineProps<
  ComboboxInputProps & {
    class?: HTMLAttributes['class']
  }
>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <div class="flex items-center justify-between border-b px-3" cmdk-input-wrapper datacy="cmdk-input-wrapper">
    <div class="flex grow items-center">
      <rq-icon class="mr-2 shrink-0 opacity-50" icon="lucide:search" />
      <ComboboxInput
        v-bind="{ ...forwardedProps, ...$attrs }"
        auto-focus
        :class="
          cn(
            'flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-gray-500 disabled:cursor-not-allowed disabled:opacity-50',
            props.class
          )
        "
      />
    </div>
    <slot name="clear" />
  </div>
</template>
