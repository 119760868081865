<script setup lang="ts">
import type { PropType } from 'vue'
import { useRoute } from 'vue-router'

import type { ProgramModel } from '@/capability/program/ProgramModel'

import { getLinks, type LinkType } from './program-page-navigation'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'

const route = useRoute()

defineProps({
  programModel: {
    type: Object as PropType<ProgramModel>,
    required: true
  }
})

const dealId = route.params.dealId as string
const programId = route.params.programId as string

const links = getLinks({ dealId, programId })

const isCurrentRoute = (link: LinkType): boolean => {
  if (link.to.name === route.name) {
    return true
  } else if (link.to.name === 'ProgramStructure' && route.name === 'ProgramComponentBase') {
    return true
  } else if (route.name === 'programMarketDetailed' && link.to.name === route.meta.priorRouteLocation) {
    return true
  } else if (route.query.view && route.query.view === link.to.name) {
    return true
  }
  return false
}
</script>

<template>
  <div class="flex space-x-2">
    <template v-for="link in links" :key="link.icon">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <Button
              as-child
              :id="link.name"
              class="text-none mx-1 my-auto rounded-lg px-3 py-1"
              :class="isCurrentRoute(link) ? 'bg-info' : 'unselected-link'"
              :disabled="link.unavailable"
              :datacy="`Button-${link.name}`"
              size="lg"
              icon="square"
            >
              <router-link v-permission="[link.name, programModel]" :to="link.to">
                <rq-icon :icon="link.icon" :class="isCurrentRoute(link) ? 'text-white' : 'text-info'" />
              </router-link>
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            {{ link.tooltipText }}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </template>
  </div>
</template>

<style scoped lang="scss">
.unselected-link {
  background-color: hsla(0, 0%, 97%);
  &:hover {
    background-color: hsla(0, 0%, 90%);
  }
}
</style>
