<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, ref } from 'vue'

import { cn, twMerge } from '@/lib/utils'
import { truncateText } from '@/lib/utils/formatting'

import type { LineItemDtoTypeEnum } from 'typescript-core-api-client/dist/api'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/component/arqu-components/shadcn/ui/dropdown-menu'
import { Label } from '@/component/arqu-components/shadcn/ui/label'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'

const props = defineProps({
  modelValue: {
    type: Array as PropType<LineItemDtoTypeEnum[]>,
    required: false,
    default: () => []
  },
  items: {
    type: Array as PropType<LineItemDtoTypeEnum[]>,
    required: false,
    default: () => []
  },
  contentClass: {
    type: String,
    required: false,
    default: ''
  }
})

const emit = defineEmits<{
  (e: 'update:modelValue', payload: LineItemDtoTypeEnum[]): void
}>()

const TRUNCATE_LENGTH = 48

const lineItems = computed({
  get(): LineItemDtoTypeEnum[] {
    return props.modelValue as LineItemDtoTypeEnum[]
  },
  set(newValue: LineItemDtoTypeEnum[]): void {
    emit('update:modelValue', newValue)
  }
})

const open = ref<boolean>(false)

function handleOpen(e: boolean) {
  if (e) {
    open.value = e
  }
}

const displayText = computed(() => {
  if (!lineItems.value.length) {
    return 'Select Multiple'
  }
  return props.items.filter((i) => !!i && lineItems.value.includes(i)).join(',\n')
})

function isSelected(lineItem: LineItemDtoTypeEnum): boolean {
  return lineItems.value.includes(lineItem)
}

function handleSelect(lineItem: LineItemDtoTypeEnum) {
  if (isSelected(lineItem)) {
    lineItems.value = lineItems.value.filter((d) => d !== lineItem)
  } else {
    lineItems.value = [...lineItems.value, lineItem]
  }
}

const allSelected = computed(() => lineItems.value.length > 0 && lineItems.value.length === props.items.length)
const someSelected = computed(() => lineItems.value.length > 0 && !allSelected.value)

const allChecked = computed(() => {
  if (allSelected.value) return true
  if (someSelected.value) return 'indeterminate'
  return false
})

function handleAllChecked() {
  if (allSelected.value) {
    lineItems.value = []
  } else {
    lineItems.value = [...props.items]
  }
}
</script>

<template>
  <div class="col-span-1 lg:col-span-2">
    <Label>Line Item Type</Label>
    <DropdownMenu :open="open" @update:open="handleOpen">
      <DropdownMenuTrigger as-child>
        <Button
          variant="outline"
          :class="twMerge('flex w-full items-center justify-between', lineItems.length ? 'text-gray-950' : 'text-gray-500')"
          :disabled="!items.length"
        >
          <TooltipProvider :disabled="displayText.length < TRUNCATE_LENGTH">
            <Tooltip>
              <TooltipTrigger as-child>
                <span>{{ truncateText(displayText, TRUNCATE_LENGTH) }}</span>
              </TooltipTrigger>
              <TooltipContent align="start" class="max-w-[400px] flex-wrap">{{ displayText }}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <rq-icon icon="lucide:chevron-down" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        :class="cn('max-h-64 w-full min-w-[400px] overflow-y-auto', contentClass)"
        @interact-outside="open = false"
      >
        <DropdownMenuCheckboxItem :checked="allChecked" class="border-b" @click.prevent="handleAllChecked">
          Select All
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          v-for="item in items"
          :key="item"
          :value="item"
          :checked="isSelected(item as LineItemDtoTypeEnum)"
          @click.prevent="handleSelect(item as LineItemDtoTypeEnum)"
        >
          {{ item }}
        </DropdownMenuCheckboxItem>
      </DropdownMenuContent>
    </DropdownMenu>
  </div>
</template>

<style scoped lang="scss"></style>
