<script lang="ts">
import { DocumentChatRequestAgentTypeEnum } from 'typescript-core-api-client'

export type Props = {
  dealId?: string
  docId?: string
  pageNumber?: number
  keywords?: string[]
  greetingText?: string
  agentTypes?: DocumentChatRequestAgentTypeEnum[]
  modelValue?: boolean
}
</script>

<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'
import { useAcl } from 'vue-simple-acl/src'
import { useStorage } from '@vueuse/core'
import { useCookies } from '@vueuse/integrations/useCookies'

import { STOP_WORDS } from '@/lib/utils/stopWords'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notification'

import { isTruthy } from '@/capability/boolean/booleanUtil'
import { determineCookieDomainFromHostnameIfNeeded } from '@/capability/cookie/cookieUtil'
import { documentService } from '@/capability/document/services'
import type { ChatLog } from '@/capability/document/types'
import { getAnswer } from '@/capability/document/types'
import { systemEventCaptureService } from '@/capability/event/SystemEventCaptureService'
import { isJson } from '@/capability/json/jsonUtil'
import { generateUuid } from '@/capability/uuid/uuidUtil'
import { type DocumentChatResponse, type DocumentDto } from 'typescript-core-api-client'

import AIChatFeedback from '@/component/chat/AIChatFeedback.vue'
import PromptCreate from '@/component/prompt/PromptCreate.vue'

const props = withDefaults(defineProps<Props>(), {
  dealId: undefined,
  docId: undefined,
  pageNumber: 0,
  keywords: () => [],
  greetingText: "Hi there! I'm Ella, your AI assistant. Let me help you navigate with ease. Just tell me what you're looking for.",
  agentTypes: () => [
    DocumentChatRequestAgentTypeEnum.Qa,
    DocumentChatRequestAgentTypeEnum.QuoteExtraction,
    DocumentChatRequestAgentTypeEnum.KnowledgeBased,
    DocumentChatRequestAgentTypeEnum.General
  ],
  modelValue: false
})

const emit = defineEmits<{
  (e: 'update:pageNumber', pageNumber: number): void
  (e: 'update:docId', docId: string): void
  (e: 'update:keywords', keywords: string[]): void
}>()

const acl = useAcl()
const authStore = useAuthStore()
const notificationStore = useNotificationStore()

const { get, set } = useCookies([`rq-ai-chat-clicked-${import.meta.env.VITE_ARQU_ENVIRONMENT}`])
const sessionId = useStorage(
  `rq-chat-session-${props.dealId ?? 'noDealId'}-${props.docId ?? 'noDocId'}-${import.meta.env.VITE_ARQU_ENVIRONMENT}`,
  generateUuid()
)

const textarea = ref()
const chatLogRef = ref()
const chatLoadingRef = ref()
const question = ref('')
const agentType = ref(props.agentTypes![0])
const chatInitLoading = ref(false)
const chatLoading = ref(false)
const chatLogs = ref<ChatLog[]>(await getChatHistory())
const parentOpen = defineModel('open', { type: Boolean })
const internalOpen = ref<boolean>(false)

const open = computed({
  get() {
    return parentOpen.value ?? internalOpen.value
  },
  set(value) {
    if (parentOpen.value !== undefined) {
      parentOpen.value = value
    } else {
      internalOpen.value = value
    }
  }
})

const clicked = computed({
  get(): boolean {
    return get(`rq-ai-chat-clicked-${import.meta.env.VITE_ARQU_ENVIRONMENT}`)
  },
  set(newValue: boolean): void {
    set(`rq-ai-chat-clicked-${import.meta.env.VITE_ARQU_ENVIRONMENT}`, newValue, {
      path: '/',
      maxAge: 60 * 60 * 24 * 30,
      domain: determineCookieDomainFromHostnameIfNeeded(typeof window !== 'undefined' ? window?.location?.hostname : '')
    })
  }
})

// chat history is only supported per document
async function getChatHistory(): Promise<ChatLog[]> {
  try {
    const chatHistory = await documentService.getChatHistory({
      dealId: props.dealId,
      documentId: props.docId,
      agentType: agentType.value,
      sessionId: sessionId.value
    })
    return (chatHistory.messages ?? [])
      .map((message, index) => {
        const answer = message.substring(message.indexOf(':') + 1)
        const sourceDocuments = chatHistory.sourceDocuments?.[index] ?? []
        if (message.startsWith('ai:')) {
          return { text: answer, isUser: false, documents: sourceDocuments, index: 0 } as ChatLog
        } else if (message.startsWith('human:')) {
          return { text: answer, isUser: true, index: 0 } as ChatLog
        }
      })
      .filter(
        (message) =>
          !(message?.text ?? '').startsWith('system:') || !(message?.text ?? '').startsWith("ai:Hi there! I'm Ella, your AI assistant.")
      ) as ChatLog[]
  } catch (err) {
    console.log('err', err)
    notificationStore.publishOneOrMoreErrUnhandled(err)
    return []
  }
}

const _docId = computed({
  get(): string {
    return props.docId as string
  },
  set(value: string) {
    emit('update:docId', value)
  }
})

const _pageNumber = computed({
  get() {
    return props.pageNumber
  },
  set(value) {
    emit('update:pageNumber', value)
  }
})

function handleKeyDown() {
  window.scrollTo(0, document.body.scrollHeight + 55)
}

async function handleSubmit() {
  if (question.value) {
    const question_copy = question.value
    chatLogs.value.push({ text: question_copy, isUser: true, documents: [], rating: undefined, question: undefined })
    question.value = ''
    await nextTick()
    chatLoadingRef.value.scrollIntoView({ behavior: 'smooth' })
    // chatLogRef.value.scrollTop = chatLogRef.value.scrollHeight
    await getChatResponse(question_copy)
  }
}

async function getChatResponse(question: string, regenerate: boolean = false): Promise<ChatLog> {
  chatLoading.value = true
  await nextTick()
  chatLogRef.value.scrollTop = chatLogRef.value.scrollHeight + 55
  const chatLog: ChatLog = {
    isUser: false,
    rating: undefined,
    index: 0,
    question
  }
  try {
    const response: DocumentChatResponse = await documentService.chat({
      dealId: props.dealId,
      docId: props.docId,
      agentType: agentType.value,
      question: question as string,
      sessionId: sessionId.value as string,
      regenerate
    })
    chatLog.text = response.answer
    chatLog.documents = response.documents ?? []
    logChatEvent(chatLog, 'success')
    return chatLog
  } catch (e) {
    notificationStore.publishOneOrMoreErrUnhandled(e)
    chatLog.text = 'There was an error generating that response. Either try again or ask a new question.'
    chatLog.documents = []
    logChatEvent(chatLog, 'error')
  } finally {
    chatLoading.value = false
    chatLogs.value.push(chatLog)
    await nextTick()
    chatLogRef.value.scrollTop = chatLogRef.value.scrollHeight + 55
  }
}

async function regenerate() {
  chatLogs.value.pop()
  const question = chatLogs.value.at(-1)?.text
  if (question) {
    await getChatResponse(question, true)
  }
}

async function newChat() {
  try {
    chatLogs.value = []
    await nextTick()
    await documentService.clearChatHistory({
      dealId: props.dealId,
      documentId: props.docId,
      agentType: agentType.value,
      sessionId: sessionId.value
    })
    notificationStore.publishSuccessMessage('Chat history cleared')
  } catch (e) {
    notificationStore.publishOneOrMoreErrUnhandled(e)
  }
}

const hasAnswer = (chatLog: ChatLog) => chatLog?.text?.trim() !== "I don't know."

const hasDocuments = (chatLog: ChatLog) => chatLog?.documents?.length > 0

function goToPageBtnClicked(doc: DocumentDto) {
  // replace all punctuation with spaces
  const keywords = doc.answer
    .toLowerCase()
    .replaceAll('\n', ' ')
    .replaceAll(/[./#!%^&*;:{}=\-_`~()]/g, ' ')
    .replaceAll('"', '')
    .replaceAll("'", '')
    .replaceAll('?', '')
    .replaceAll('!', '')
    .replaceAll('$', '')
    .split(' ')
    .filter((token) => !STOP_WORDS.includes(token) && token.length > 0)

  emit('update:keywords', keywords)
  _pageNumber.value = doc.metadata['pageNumber']
  _docId.value = doc.metadata['documentId']
}

function logChatEvent(chatLog: ChatLog, resourceCrudl: string, resourceCrudlResult: string | undefined = undefined) {
  systemEventCaptureService.fireAndForgetUserActivity({
    pageId: 'document-preview',
    resourceType: 'document-chat-log',
    resourceCrudl: resourceCrudl,
    resourceCrudlResult: resourceCrudlResult,
    workerAction: chatLog?.question,
    subResource: chatLog?.text,
    dealId: props.dealId,
    documentId: props.docId
  })
}

const normalized = (text: string | undefined) => text?.replaceAll('\n', '<br>')

const displayNote = computed(() => !chatInitLoading.value && !isTruthy(clicked.value))

function handleOpen() {
  if (displayNote.value) {
    clicked.value = true
  }
  open.value = !open.value
}

const queryItems = computed(() => {
  return [
    {
      text: 'General',
      value: DocumentChatRequestAgentTypeEnum.General
    },
    {
      text: 'Document Q&A',
      value: DocumentChatRequestAgentTypeEnum.Qa
    },
    {
      text: 'Quote Extraction',
      value: DocumentChatRequestAgentTypeEnum.QuoteExtraction
    },
    {
      text: 'Knowledge Base',
      value: 'KnowledgeBased'
    }
  ].filter((item) => props.agentTypes!.includes(item.value))
})

function previousAnswer(chatLog: ChatLog) {
  if (chatLog?.index > 0) {
    chatLog.index--
  }
}

function nextAnswer(chatLog: ChatLog) {
  if (chatLog?.index < chatLog?.documents?.length - 1) {
    chatLog.index++
  }
}

const queryLabel = computed(() => (agentType.value === DocumentChatRequestAgentTypeEnum.Qa ? 'Enter your question' : 'Send a message'))

const displayRegenerateButton = computed(() => chatLogs.value.filter((c) => c.isUser).length > 0)
const regenerateDisabled = computed(() => chatLoading.value)
</script>

<template>
  <slot name="trigger">
    <rq-btn
      datacy="ellaChatBtn"
      :disabled="chatInitLoading"
      icon="rounded"
      variant="ghost-primary"
      class="bottom-6 right-6 z-40 hidden sm:fixed sm:block"
      @click="handleOpen"
    >
      <rq-icon icon="mdi:clippy" class="h-12 w-12" />
      <div
        v-if="displayNote"
        class="absolute bottom-12 right-10 z-[250] min-w-[300px] rounded-lg bg-primary-200 px-2 py-3 text-primary-800 shadow"
      >
        {{ greetingText }}
        <rq-btn icon="rounded" size="micro" class="absolute -right-1 -top-1" @click.stop="clicked = true">
          <rq-icon size="x-small" icon="lucide:x" />
        </rq-btn>
      </div>
    </rq-btn>
  </slot>
  <transition
    enter-active-class="transition ease-out duration-300"
    enter-from-class="opacity-0 scale-0"
    enter-to-class="opacity-100 scale-100"
    leave-active-class="transition ease-in duration-300"
    leave-from-class="opacity-100 scale-100"
    leave-to-class="opacity-0 scale-0"
  >
    <div
      v-if="open"
      class="fixed bottom-24 right-8 z-[250] flex w-[400px] flex-col rounded-lg border border-primary-100 bg-white shadow-md"
    >
      <div class="flex items-center justify-between rounded-t-lg bg-primary-400 pl-3 text-white">
        <span>Chat with Ella</span>
        <rq-btn datacy="ellaChatCloseBtn" variant="ghost-primary" class="group rounded-l-none rounded-br-none" @click="open = false">
          <rq-icon icon="lucide:minus" class="text-white group-hover:text-primary-800" />
        </rq-btn>
      </div>
      <div v-if="chatLogs.length > 0" class="flex w-full justify-end">
        <rq-btn variant="ghost-primary" size="sm" class="space-x-1 rounded-none rounded-bl-md" @click="newChat">
          <span>Clear Chat History</span>
          <rq-icon icon="mdi:notification-clear-all" />
        </rq-btn>
      </div>

      <div class="flex grow flex-col justify-end px-2 py-3">
        <template v-if="!chatLogs.length">
          <div class="flex space-x-1 py-2">
            <rq-avatar user-name="Ella" />
            <p class="grow rounded-lg rounded-tl-none bg-gray-200 p-2 text-sm text-gray-700">
              Meet Ella, our new AI assistant! She's here to help you effortlessly navigate this document. Just ask her what you're looking
              for.
            </p>
          </div>
        </template>
        <ul v-else ref="chatLogRef" id="chat-log-ref" class="max-h-[40vh] overflow-y-auto">
          <li v-for="chatLog in chatLogs" :key="chatLog.text" class="my-3">
            <template v-if="chatLog.isUser">
              <div class="flex w-full justify-between space-x-1">
                <p
                  class="grow rounded-lg rounded-tr-none bg-primary-100 p-2 text-sm text-primary-700"
                  v-html="normalized(chatLog.text!)"
                ></p>
                <rq-avatar :user-name="authStore.getUser!.email" class="bg-primary-100 text-primary-700" />
              </div>
            </template>
            <template v-else>
              <div class="flex w-full justify-between space-x-1">
                <rq-avatar user-name="Ella" />
                <div class="grow">
                  <JsonViewer v-if="isJson(chatLog.text)" :value="JSON.parse(chatLog.text!)" copyable boxed theme="dark" expanded />
                  <p
                    v-else
                    class="rounded-lg rounded-tl-none bg-gray-200 p-2 text-sm text-gray-700"
                    v-html="normalized(getAnswer(chatLog))"
                  ></p>
                  <div class="justify-left flex flex-wrap">
                    <div v-if="hasAnswer(chatLog) && hasDocuments(chatLog)" class="flex items-center pt-1 text-xs">
                      Source:
                      <rq-btn
                        v-if="chatLog.documents"
                        size="sm"
                        variant="primary-outline"
                        @click="goToPageBtnClicked(chatLog.documents[chatLog.index])"
                      >
                        {{ chatLog.documents[chatLog.index].metadata['filename'] }} Page:
                        {{ chatLog.documents[chatLog.index].metadata['pageNumber'] }}
                      </rq-btn>
                    </div>
                    <div class="flex items-center" v-if="chatLog.documents && chatLog.documents.length > 1">
                      <rq-btn size="sm" icon="square" variant="ghost" datacy="market-detail-back-button" @click="previousAnswer(chatLog)">
                        <rq-icon icon="lucide:arrow-left" />
                      </rq-btn>
                      {{ chatLog.index + 1 }}/{{ chatLog.documents?.length }}
                      <rq-btn size="sm" icon="square" variant="ghost" datacy="market-detail-back-button" @click="nextAnswer(chatLog)">
                        <rq-icon icon="lucide:arrow-right" />
                      </rq-btn>
                    </div>
                    <AIChatFeedback :chat-log="chatLog" :doc-id="docId!" :deal-id="dealId" />
                    <PromptCreate
                      v-if="agentType == DocumentChatRequestAgentTypeEnum.QuoteExtraction"
                      :deal-id="dealId as string"
                      :prompt-text="chatLog.question"
                      :agent-type="agentType"
                    />
                  </div>
                </div>
              </div>
            </template>
          </li>
          <li v-show="chatLoading" id="chat-loading" ref="chatLoadingRef">
            <div class="my-3 flex w-full justify-between space-x-1">
              <rq-avatar user-name="Ella" />
              <p class="flex w-full items-center space-x-1 rounded-lg rounded-tl-none bg-gray-200 px-2 pb-1 pt-2 text-sm text-gray-700">
                <span class="blue-circle h-3 w-3 animate-bounce rounded-full bg-blue-500"></span>
                <span class="green-circle h-3 w-3 animate-bounce rounded-full bg-green-500"></span>
                <span class="red-circle h-3 w-3 animate-bounce rounded-full bg-red-500"></span>
              </p>
            </div>
          </li>
        </ul>
        <rq-btn
          v-if="displayRegenerateButton"
          size="xs"
          icon="rounded"
          variant="outline"
          class="mb-2 space-x-1"
          :disabled="regenerateDisabled"
          @click="regenerate"
        >
          <rq-icon icon="lucide:refresh-cw" size="sm" />
          <span>Regenerate</span>
        </rq-btn>
        <rq-listbox-single
          v-if="acl.anyRole(['admin', 'rs'])"
          v-model="agentType"
          label="Agent Type"
          content-class="z-[251]"
          wrapper-class="border-t"
          :position-above="true"
          :items="queryItems"
        />
        <rq-textarea
          v-model="question"
          ref="textarea"
          auto-grow
          :label="queryLabel"
          append-icon="mdi-send"
          append-location="bottom"
          :autocomplete="false"
          :read-only="chatLoading"
          rows="1"
          @click:append-icon="handleSubmit"
          @keydown.enter.exact.prevent="handleSubmit"
          @keydown="handleKeyDown"
        />
      </div>
    </div>
  </transition>
</template>

<style scoped>
.blue-circle {
  animation-delay: 0.1s !important;
}

.green-circle {
  animation-delay: 0.2s !important;
}

.red-circle {
  animation-delay: 0.3s !important;
}

.animate-bounce {
  animation-name: bounce !important;
  animation-duration: 1s !important;
  animation-iteration-count: infinite !important;
  animation-fill-mode: both !important;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
</style>
