<script setup lang="ts">
import { onMounted } from 'vue'
import { useHead } from 'unhead'

import { isCarrier, isCoBroker, isRetailer, isRiskSpecialist } from '@/lib/utils/user'
import { useAuthStore } from '@/stores/auth'

import type { UserModel } from '@/capability/user/model'

import Toaster from '@/component/arqu-components/shadcn/ui/toast/Toaster.vue'
import ProgramLineItemInputDialog from '@/component/program/program-line-items/ProgramLineItemInputDialog.vue'

const authStore = useAuthStore()

if (process.env.NODE_ENV === 'production') {
  const script = []
  if (import.meta.env.VITE_ARQU_ENVIRONMENT === 'staging' || import.meta.env.VITE_ARQU_ENVIRONMENT === 'production') {
    script.push({
      key: 'init-pendo',
      src: '/scripts/pendo.js'
    })
  }
  if (import.meta.env.VITE_GTM_KEY) {
    script.push({
      key: 'gtm',
      src: `/scripts/gtm-${import.meta.env.VITE_GTM_KEY}.js`
    })
  }
  useHead({ script })
}

const gtmScript = document.createElement('noscript')
const iframe = document.createElement('iframe')
iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=GTM-${import.meta.env.VITE_GTM_KEY}`)
iframe.setAttribute('height', '0')
iframe.setAttribute('width', '0')
iframe.setAttribute('style', 'display:none;visibility:hidden')
gtmScript.appendChild(iframe)

document.body.appendChild(gtmScript)

type PendoInitializerType = {
  visitor: {
    id: string
    email: string
    role: 'rs' | 'carrier' | 'retailer' | 'cobroker' | ''
    environment: string
  }
  account: {
    id: string
    name?: string
  }
}

function getInitializer(user: UserModel): PendoInitializerType {
  let role: 'rs' | 'carrier' | 'retailer' | 'cobroker' | '' = ''
  if (isRiskSpecialist(user)) {
    role = 'rs'
  } else if (isCarrier(user)) {
    role = 'carrier'
  } else if (isRetailer(user)) {
    role = 'retailer'
  } else if (isCoBroker(user)) {
    role = 'cobroker'
  }
  return {
    visitor: {
      id: user.id,
      email: user.email,
      role,
      environment: import.meta.env.VITE_ARQU_ENVIRONMENT
    },
    account: {
      id: user.organizationId
    }
  } as PendoInitializerType
}

onMounted(async () => {
  // Need to use a setTimeout for Pendo because without it seems as if it is not recognized on the window object
  setTimeout(() => {
    if (window && Object.keys(window).includes('pendo')) {
      if (authStore.user) {
        window.pendo!.initialize(getInitializer(authStore.user))
      } else {
        authStore.$subscribe((mutation, state) => {
          if (mutation.type === 'patch object' && mutation.storeId === 'auth') {
            const keys = Object.keys(mutation.payload) ?? {}
            if (keys.includes('user') && !!mutation.payload.user) {
              window.pendo!.initialize(getInitializer(state.user!))
            }
          }
        })
      }
    }
  }, 2500)
})
</script>

<template>
  <div class="font-sans">
    <Suspense>
      <router-view />
    </Suspense>
  </div>
  <div id="tooltip"></div>
  <ProgramLineItemInputDialog />
  <Toaster />
</template>
