import { v1 as uuidv1, v4 as uuidv4 } from 'uuid'

export function generateV4Uuid(): string {
  return uuidv4()
}

export function generateUuid(): string {
  return generateV4Uuid()
}

export function convertV1UuidToV6(v1Uuid: string): string {
  // const options: any = {}

  function generateId() {
    const raw = v1Uuid

    const prefix = `${raw.substring(15, 18)}${raw.substring(9, 13)}${raw.substring(0, 5)}6${raw.substring(5, 8)}`
    const prefixFormatted = `${prefix.substring(0, 8)}-${prefix.substring(8, 12)}-${prefix.substring(12)}`

    return `${prefixFormatted}${raw.substring(18)}`
  }

  return generateId()
}

export function generateV6Uuid(): string {
  return convertV1UuidToV6(uuidv1())
}

export function isValidUuid(stringUnderTest: string): boolean {
  if (stringUnderTest == null) {
    return false
  }
  if (stringUnderTest.match(/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/i) != null) {
    return true
  }

  return false
}

export function isUuidStrictlyAssignedAsEmpty(stringUnderTest: string): boolean {
  return stringUnderTest === '00000000-0000-0000-0000-000000000000'
}
