import { reactive, ref } from 'vue'
import { defineStore } from 'pinia'

import { NotFoundException } from '@/capability/exception/NotFoundException'

import type { ToastProps } from '@/component/arqu-components/shadcn/ui/toast'
import { useToast } from '@/component/arqu-components/shadcn/ui/toast/use-toast'

export type PublishByTypePayloadType = {
  message: string
  type: 'error' | 'info' | 'warning' | 'success'
  options?: ToastProps
}

export const useNotificationStore = defineStore('notification', () => {
  const { toast } = useToast()

  const notificationSignal = ref<string>('')
  const notificationMeta = reactive({})

  function publishSuccessMessage(message: string, options?: ToastProps) {
    toast({ description: message, variant: 'success', ...options })
  }

  function publishInfoMessage(message: string, options?: ToastProps) {
    toast({ description: message, variant: 'info', ...options })
  }

  function publishWarningMessage(message: string, options?: ToastProps) {
    toast({ description: message, variant: 'warning', ...options })
  }

  function publishErrorMessage(message: string, options?: ToastProps) {
    toast({ description: message, variant: 'error', ...options })
  }

  function publishOneOrMoreErrUnhandled(oneOrMoreErr: Error, options?: ToastProps) {
    let message: string
    if (oneOrMoreErr instanceof NotFoundException) {
      message = 'Not Found'
    } else if ((oneOrMoreErr as any)?.response?.data?.code == 'validation_error') {
      message = (oneOrMoreErr as any)?.response?.data?.fieldErrors
        ?.map((fieldError: any) => `${fieldError.field} ${fieldError.message}`)
        .join('\n')
    } else {
      message = (oneOrMoreErr as any)?.response?.data?.message || oneOrMoreErr || 'Unknown Error'
    }

    toast({ description: message, variant: 'error', ...options })
  }

  function publishByType(payload: PublishByTypePayloadType) {
    const { message, type, options } = payload
    switch (type) {
      case 'success':
        toast({ description: message, variant: 'success', ...options })
        break
      case 'error':
        toast({ description: message, variant: 'error', ...options })
        break
      case 'info':
        toast({ description: message, variant: 'info', ...options })
        break
      case 'warning':
        toast({ description: message, variant: 'warning', ...options })
        break
    }
  }

  return {
    publishSuccessMessage,
    publishInfoMessage,
    publishWarningMessage,
    publishErrorMessage,
    publishOneOrMoreErrUnhandled,
    publishByType,
    notificationSignal,
    notificationMeta
  }
})

export type NotificationStoreType = typeof useNotificationStore
