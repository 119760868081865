import * as stringUtil from '@/lib/utils/formatting/string'

import { PlugLayerTreatmentOption } from '@/capability/layer/PlugLayerTreatmentOption'
import type { SegmentSlashSubLayerDto } from '@/capability/layer/SubLayerDto'
import { d4l, getLogger } from '@/capability/log'
import type { LayerDto, LayerSegmentDto } from 'typescript-core-api-client'
const LOG = getLogger('layer.layerDtoUtil')
// Do NOT import towerDtoUtil.ts

export function tryGetLimit(layerDto: LayerDto | undefined | null): number | undefined | null {
  return layerDto?.coverage?.limit?.amount
}

export function tryGetTop(layerDto: LayerDto | undefined | null): number | undefined | null {
  const limit = tryGetLimit(layerDto)
  const excess = tryGetExcess(layerDto)
  if (limit != null && excess != null) {
    return limit + excess
  }
  return undefined
}

export function tryGetPercentage(layerDto: LayerDto | undefined | null): number | undefined | null {
  return layerDto?.percentage
}

/**
 * Increment or decrement the amount of coverage for a given layer
 * by the specified amount (by changing the limit).
 *
 * Negative value decreases coverage.
 *
 * When decreasing coverage, the limit cannot be made less than the excess
 *
 * @param layerDto - The layer which we we are changing coverage for
 * @param limitDelta desired positive or negative change in Coverage amount
 * @returns The remainder -- any amount of coverage that could NOT be removed from
 * this layer (because the layer didn't have enough coverage available)
 */

export function changeLimitInPlace(layerDto: LayerDto, limitDelta: number): number /* remainder */ {
  LOG.debug(`changeLimitInPlace(): Entering with limitDelta = ${d4l(limitDelta)}`)
  const existingLimit: number | undefined | null = tryGetLimit(layerDto)

  if (existingLimit == null) {
    LOG.error(`changeLimitInPlace(): existingLimit is NULL`)
    throw new Error('Layer limit is undefined')
  }
  if (limitDelta == null) {
    LOG.error(`changeLimitInPlace(): limitDelta not set`)
    throw new Error('limitDelta not set')
  }
  if (limitDelta >= 0) {
    LOG.trace(`changeLimitInPlace(): The layer limit is INCREASING by ${limitDelta}`)
    layerDto.coverage!.limit!.amount = layerDto.coverage!.limit!.amount! + limitDelta
    return 0
  } else {
    // The layer limit is DECREASING
    if (Math.abs(limitDelta) <= existingLimit) {
      layerDto.coverage!.limit!.amount = layerDto.coverage!.limit!.amount! + limitDelta // limitDelta is negative
      LOG.trace(
        `changeLimitInPlace(): The layer limit is decreasing by LESS than the current limit amount (which is ${existingLimit}).  The new layer limit is set to ${
          layerDto.coverage!.limit!.amount
        }`
      )
      return 0
    } else {
      // decreasing by MORE than current coverage amount
      const remainder = Math.abs(existingLimit + limitDelta) // limitDelta is negative
      LOG.debug(
        `changeLimitInPlace(): Layer limit is decreasing by MORE than the current limit amount (which is ${existingLimit}),  limitDelta = ${limitDelta},  remainder = ${remainder}`
      )
      layerDto.coverage!.limit!.amount = 0
      return remainder
    }
  }
}

export function tryGetExcess(layerDto: LayerDto | undefined | null): number | undefined | null {
  return layerDto?.coverage?.excess?.amount
}

export function tryGetNumSegments(layerDto: LayerDto | undefined | null): number | undefined | null {
  return layerDto != null ? layerDto.layers?.length || 0 : undefined
}

export function tryGetNumPlugSegments(layerDto: LayerDto | undefined | null): number | undefined | null {
  return layerDto != null ? layerDto.layers?.filter((x) => x.plug === true).length || 0 : undefined
}

export function hasSegmentsAndTheyAreAllPlugs(layerDto: LayerDto | undefined | null): boolean {
  const numSegments = tryGetNumSegments(layerDto)
  return numSegments != null && numSegments > 0 && numSegments === tryGetNumPlugSegments(layerDto)
}

export function doLayersLimitAndExcessMatch(layerDto1: LayerDto, layerDto2: LayerDto): boolean {
  const limit1 = tryGetLimit(layerDto1)
  const excess1 = tryGetExcess(layerDto1)
  const limit2 = tryGetLimit(layerDto2)
  const excess2 = tryGetExcess(layerDto2)
  if (limit1 != null && excess1 != null && limit1 === limit2 && excess1 === excess2) {
    return true
  }
  return false
}

export function doesLimitAndExcessMatchLayer(limit: number, excess: number, layerDto: LayerDto): boolean {
  if (limit == null || excess == null) {
    return false
  }
  const layersLimit = tryGetLimit(layerDto)
  const layersExcess = tryGetExcess(layerDto)

  if (limit === layersLimit && excess === layersExcess) {
    return true
  }
  return false
}

export function doSegmentsHaveMatchingIdentity(one: Readonly<SegmentSlashSubLayerDto>, two: Readonly<SegmentSlashSubLayerDto>) {
  if (one != null && two != null) {
    const oneId = one.id
    const twoId = two.id

    if (oneId != null && oneId === twoId) {
      LOG.debug(`doSegmentsHaveMatchingIdentity(): Returning TRUE.   It's a match.`)
      return true
    }
  }
  LOG.debug(`doSegmentsHaveMatchingIdentity(): Returning FALSE.   No match`)
  return false
}

export function tryGetSegmentAtAtIndex(layerDto: LayerDto | undefined | null, index: number): LayerSegmentDto | undefined | null {
  return layerDto?.layerSegments != null ? layerDto?.layerSegments[index] : undefined
}

export function assureLimitPresent(layerDto: LayerDto): void {
  if (layerDto.coverage == null) {
    layerDto.coverage = {}
  }
  if (layerDto.coverage.limit == null) {
    layerDto.coverage.limit = {}
  }
}

export function setLimit(layerDto: LayerDto, value: number): void {
  if (layerDto.coverage == null) {
    layerDto.coverage = {}
  }
  if (layerDto.coverage.limit == null) {
    layerDto.coverage.limit = {}
  }
  layerDto.coverage.limit.amount = value
}

export function setPercentage(segment: SegmentSlashSubLayerDto, value: number): void {
  segment.percentage = value
}

export function setExcess(layerDto: LayerDto, value: number): void {
  if (layerDto.coverage == null) {
    layerDto.coverage = {}
  }
  if (layerDto.coverage.excess == null) {
    layerDto.coverage.excess = {}
  }
  layerDto.coverage.excess.amount = value
}

export function assureExcessPresent(layerDto: LayerDto): void {
  if (layerDto.coverage == null) {
    layerDto.coverage = {}
  }
  if (layerDto.coverage.excess == null) {
    layerDto.coverage.excess = {}
  }
}

export function extractCopyModifyStructureConcerns(immutableLayerDto: LayerDto): LayerDto {
  LOG.trace('extractCopyModifyStructureConcerns(): Entering')

  if (typeof immutableLayerDto === 'undefined' || immutableLayerDto == null) return immutableLayerDto as any

  const cloneLayer: LayerDto = {}
  cloneLayer.name = immutableLayerDto.name

  if (immutableLayerDto.coverage != null) {
    cloneLayer.coverage = {}

    if (immutableLayerDto.coverage.limit != null) {
      cloneLayer.coverage.limit = {}
      cloneLayer.coverage.limit.amount = immutableLayerDto.coverage.limit.amount
      // I don't think we need the units so I'm not copying that
    }

    if (immutableLayerDto.coverage.excess != null) {
      cloneLayer.coverage.excess = {}
      cloneLayer.coverage.excess.amount = immutableLayerDto.coverage.excess.amount
      // I don't think we need the units so I'm not copying that
    }
  }

  // sublayers
  if (cloneLayer.layers != null) {
    cloneLayer.layers = []
    for (let ii = 0; ii < immutableLayerDto.layers!.length; ii++) {
      const sourceLayer = immutableLayerDto.layers![ii]

      if (typeof sourceLayer === 'undefined' || sourceLayer == null) {
        cloneLayer.layers.push(sourceLayer)
      } else {
        cloneLayer.layers.push(extractCopyModifyStructureConcerns(sourceLayer))
      }
    }
  } else {
    cloneLayer.layers = immutableLayerDto.layers as undefined
  }

  return cloneLayer
}

export function tryGetSortedSubLayersRightMostFirst(layerDto: LayerDto): LayerDto[] | undefined {
  if (layerDto?.layers != null) {
    return [...(layerDto.layers ?? [])].sort((a, b) => {
      let aValue = tryGetPercentage(a) || 0
      let bValue = tryGetPercentage(b) || 0

      if (aValue < bValue) {
        return 1
      } else if (aValue > bValue) {
        return -1
      }
      return 0
    })
  }

  return undefined
}

export function tryGetRightMostVisualSubLayerDto(layerDto: LayerDto): LayerDto | undefined {
  const sortedSubLayers = tryGetSortedSubLayersRightMostFirst(layerDto)
  if (sortedSubLayers == null || sortedSubLayers.length == 0) {
    return undefined
  }

  return sortedSubLayers[0]
}

export function tryGetSortedSegmentsLeftMostFirstCopy(
  layerDto: LayerDto,
  plugLayerTreatmentOption: PlugLayerTreatmentOption
): SegmentSlashSubLayerDto[] | undefined {
  LOG.trace(
    `tryGetSortedSegmentsLeftMostFirstCopy(): Entering with plugLayerTreatmentOption = ${d4l(
      PlugLayerTreatmentOption[plugLayerTreatmentOption]
    )}`
  )

  if (layerDto?.layers != null) {
    // No natural sort?
    let sortedSegments = [...(layerDto?.layers ?? [])]

    if (plugLayerTreatmentOption === PlugLayerTreatmentOption.CONSIDER_PLUG_LAYERS_WITH_EXTRA_BUOYANCY) {
      const plugLayers: SegmentSlashSubLayerDto[] = []
      for (let ii = sortedSegments.length - 1; ii >= 0; ii--) {
        const layerDto = sortedSegments[ii]
        if (layerDto.plug) {
          sortedSegments.splice(ii, 1)
          plugLayers.unshift(layerDto)
        }
      }
      sortedSegments = plugLayers.concat(sortedSegments)
    } else if (plugLayerTreatmentOption === PlugLayerTreatmentOption.PRETEND_PLUG_LAYERS_DO_NOT_EXIST) {
      const beforeFilterCount = sortedSegments.length
      sortedSegments = sortedSegments.filter((segmentSlashSubLayerDto) => segmentSlashSubLayerDto.plug !== true)
      LOG.debug(
        `tryGetSortedSegmentsLeftMostFirstCopy(): Via PRETEND_PLUG_LAYERS_DO_NOT_EXIST, filtered out ${
          beforeFilterCount - sortedSegments.length
        } plug layers`
      )
    } else if (plugLayerTreatmentOption === PlugLayerTreatmentOption.ONLY_CONSIDER_PLUG_LAYERS) {
      sortedSegments = sortedSegments.filter((segmentSlashSubLayerDto) => segmentSlashSubLayerDto.plug === true)
    }

    if (LOG.isTrace()) {
      LOG.trace(
        `tryGetSortedSegmentsLeftMostFirstCopy(): Returning ${sortedSegments.length} segments.  sortedSegments = ${d4l(sortedSegments)}`
      )
    } else {
      LOG.debug(`tryGetSortedSegmentsLeftMostFirstCopy(): Returning ${sortedSegments.length} segments`)
    }
    return sortedSegments
  }

  return undefined
}

export function getNumSegments(immutableLayerDto: LayerDto): number {
  if (immutableLayerDto.layers != null) {
    return immutableLayerDto.layers.length
  }
  return 0
}

export function getNumPlugSegments(immutableLayerDto: LayerDto): number {
  if (immutableLayerDto.layers != null) {
    return immutableLayerDto.layers.filter((x) => x.plug === true).length
  }
  return 0
}

export function getNumNonPlugSegments(immutableLayerDto: LayerDto): number {
  if (immutableLayerDto.layers != null) {
    return immutableLayerDto.layers.filter((x) => x.plug !== true).length
  }
  return 0
}

export function hasExactlyOnePlugSegmentWhichIsOnTheRight(immutableLayerDto: LayerDto): boolean {
  if (getNumPlugSegments(immutableLayerDto) === 1 && immutableLayerDto.layers![immutableLayerDto.layers!.length - 1].plug === true) {
    return true
  }
  return false
}

export function tryGetOneAndOnlyPlugSegmentWhichIsOnTheRight(immutableLayerDto: LayerDto): SegmentSlashSubLayerDto | undefined {
  if (hasExactlyOnePlugSegmentWhichIsOnTheRight(immutableLayerDto)) {
    return immutableLayerDto.layers![immutableLayerDto.layers!.length - 1]
  }
  return undefined
}

export function hasPlugSegmentWhichIsOnTheRight(immutableLayerDto: LayerDto): boolean {
  LOG.trace(`hasPlugSegmentWhichIsOnTheRight(): Entering with immutableLayerDto = ${d4l(immutableLayerDto)}`)
  if (getNumPlugSegments(immutableLayerDto) > 0 && immutableLayerDto.layers![immutableLayerDto.layers!.length - 1].plug === true) {
    LOG.trace(`hasPlugSegmentWhichIsOnTheRight(): Returning TRUE, based on immutableLayerDto = ${d4l(immutableLayerDto)}`)
    return true
  }
  LOG.trace(`hasPlugSegmentWhichIsOnTheRight(): Returning FALSE, based on immutableLayerDto = ${d4l(immutableLayerDto)}`)
  return false
}

export function tryGetPlugSegmentWhichIsOnTheRight(immutableLayerDto: LayerDto): SegmentSlashSubLayerDto | undefined {
  if (hasPlugSegmentWhichIsOnTheRight(immutableLayerDto)) {
    return immutableLayerDto.layers![immutableLayerDto.layers!.length - 1]
  }
  return undefined
}

export function hasAnyPlugSegments(immutableLayerDto: LayerDto): boolean {
  return getNumPlugSegments(immutableLayerDto) > 0
}

export function tryFindAnyPlugSegment(immutableLayerDto: LayerDto): SegmentSlashSubLayerDto | null {
  if (immutableLayerDto.layers != null) {
    for (let ii = 0; ii < immutableLayerDto.layers.length; ii++) {
      const layerDto = immutableLayerDto.layers[ii]
      if (layerDto.plug === true) {
        return layerDto
      }
    }
  }
  return null
}

export function doSegmentPercentagesAggregateTo100(
  immutableLayerDto: LayerDto,
  plugLayerTreatmentOption: PlugLayerTreatmentOption
): boolean {
  const leftFirstSorted: SegmentSlashSubLayerDto[] | undefined = immutableLayerDto?.layers

  if (leftFirstSorted == null || leftFirstSorted.length === 0) {
    LOG.info(`doSegmentPercentagesAggregateTo100(): No segments.  Can't match`)
    return false
  }

  let aggregatePercentage: number = 0
  for (let ii = 0; ii < leftFirstSorted!.length; ii++) {
    const nextLeftMostSegment: SegmentSlashSubLayerDto = leftFirstSorted![ii]
    if (nextLeftMostSegment != null && nextLeftMostSegment.percentage != null) {
      aggregatePercentage += nextLeftMostSegment.percentage
    }
  }

  return aggregatePercentage === 100 ? true : false
}

export function calculateAggregateSegmentPercentage(
  immutableLayerDto: LayerDto,
  plugLayerTreatmentOption: PlugLayerTreatmentOption
): number | undefined {
  const leftFirstSortedSegments: SegmentSlashSubLayerDto[] | undefined = tryGetSortedSegmentsLeftMostFirstCopy(
    immutableLayerDto,
    plugLayerTreatmentOption
  )

  if (leftFirstSortedSegments == null) {
    return undefined
  }

  const percentageOfEachSegment: Array<number | undefined | null> = []
  for (let ii = 0; ii < leftFirstSortedSegments!.length; ii++) {
    const nextBottomMostSegment: SegmentSlashSubLayerDto = leftFirstSortedSegments![ii]
    const nextBottomMostSegmentPercentage = tryGetPercentage(nextBottomMostSegment)
    percentageOfEachSegment.push(nextBottomMostSegmentPercentage)
  }

  const aggregatePercentage: number = percentageOfEachSegment!
    .filter((x) => x != null)!
    .reduce((aggregation, currentValue) => {
      return aggregation! + currentValue!
    }, 0)!
  LOG.debug(
    `calculateAggregateSegmentPercentage(): Returning aggregatePercentage = ${aggregatePercentage},  based on percentageOfEachSegment = ${d4l(
      percentageOfEachSegment
    )}`
  )
  return aggregatePercentage
}

export function isPlug(immutableSegment: SegmentSlashSubLayerDto): boolean {
  if (immutableSegment.plug === true) {
    return true
  }
  return false
}

export function appendSegmentInPlaceButBeforeAnyRightmostPlugs(mutableLayerDto: LayerDto, immutableSegment: SegmentSlashSubLayerDto): void {
  if (mutableLayerDto.layers == null) {
    mutableLayerDto.layers = []
  }

  let ii = 0
  for (ii = mutableLayerDto.layers.length - 1; ii >= 0; ii--) {
    const segmentUnderTest: LayerDto = mutableLayerDto.layers[ii]
    if (isPlug(segmentUnderTest)) {
      continue
    }
  }
  mutableLayerDto.layers.splice(ii, 0, immutableSegment)
}

export function doLayersHaveMatchingIdentity(layer1: Readonly<LayerDto>, layer2: Readonly<LayerDto>) {
  if (layer1 != null && layer2 != null) {
    const layer1Id = layer1.id
    const layer2Id = layer2.id

    if (layer1Id != null && layer1Id === layer2Id) {
      LOG.debug(`doLayersHaveMatchingIdentity(): Returning TRUE.   It's a match.`)
      return true
    }
  }
  LOG.debug(`doLayersHaveMatchingIdentity(): Returning FALSE.   No match`)
  return false
}

export function tryGetSegmentMatchingIdentity(
  layerDto: LayerDto,
  immutableTemplateSegmentBearingIdentity: SegmentSlashSubLayerDto
): LayerDto | undefined {
  if (layerDto.layers != null) {
    for (let ii = 0; ii < layerDto.layers!.length; ii++) {
      const segmentUnderTest = layerDto.layers[ii]
      if (immutableTemplateSegmentBearingIdentity) {
        if (
          stringUtil.isPresent(immutableTemplateSegmentBearingIdentity.id) &&
          immutableTemplateSegmentBearingIdentity.id === segmentUnderTest.id
        ) {
          return segmentUnderTest
        }
      }
    }
  }
  return undefined
}
