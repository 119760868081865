<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, ref, watch } from 'vue'
import { z } from 'zod'

import { useZod } from '@/composables/zod'
import type { ErrorType } from '@/lib/types'

import type { LayerModel } from '@/capability/layer/LayerModel'
import type { ProgramModel } from '@/capability/program/ProgramModel'
import type { TowerModel } from '@/capability/tower/TowerModel'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/component/arqu-components/shadcn/ui/dialog'
import ProgramIndicationAssignSelectCarrier from '@/component/program/ProgramIndicationAssignSelectCarrier.vue'

const props = defineProps({
  program: {
    type: Object as PropType<ProgramModel>,
    required: true
  }
})

const stateObject = {
  selectedTower: {} as TowerModel,
  selectedLayer: {} as LayerModel,
  selectedSegment: {} as LayerModel
}

const schemaObject = {
  selectedTower: z.any().refine((v) => !!v.id, { message: 'Please select a tower', path: [] }),
  selectedLayer: z.any().refine((v) => !!v.id, { message: 'Please select a layer', path: [] }),
  selectedSegment: z.any().refine((v) => (segmentItems.value.length ? !!v.id : true), { message: 'Please select a segment', path: [] })
}

const { state, errors, resetState, resetErrors, validate } = useZod<typeof stateObject, ErrorType<typeof stateObject>>({
  stateObject,
  schemaObject
})

const dialog = ref<boolean>(false)
const selectCarrierDialog = ref<boolean>(false)

function handleSubmit() {
  const valid = validate()
  if (valid) {
    selectCarrierDialog.value = true
  }
}

watch(dialog, (value) => {
  if (value) {
    resetState()
    resetErrors()
  }
})

function closeDialog() {
  dialog.value = false
  selectCarrierDialog.value = false
}

const layerItems = computed(() => {
  if (state.selectedTower?.id) {
    // return non plug layers to map with key as label name
    const items = [] as { id: string; name: string }[]
    ;(props.program?.towers ?? [])
      ?.find((tower) => tower.id == state.selectedTower?.id)
      ?.layers?.forEach((layer) => {
        items.push({ id: layer.id!!, name: layer.getNameWithDefault()!! })
      })
    return items
  } else {
    return []
  }
})

const segmentItems = computed(() => {
  if (state.selectedLayer?.id) {
    const tower = (props.program?.towers ?? []).find((tower) => tower.id == state.selectedTower?.id)
    const layer = (tower?.layers ?? []).find((layer) => layer.id == state.selectedLayer?.id)
    return (layer?.layers ?? []).map((segment) => ({
      id: segment.id!,
      name: segment.getNameWithDefault()!
    }))
  } else {
    return []
  }
})

watch(
  () => state.selectedTower,
  () => {
    state.selectedLayer = {} as LayerModel
    state.selectedSegment = {} as LayerModel
  }
)

watch(
  () => state.selectedLayer,
  () => {
    state.selectedSegment = {} as LayerModel
  }
)
</script>

<template>
  <!--
    The following is the step 1 of the modal dialog to pick a specific tower, layer or segment.
  -->
  <Dialog v-model:open="dialog">
    <DialogTrigger as-child>
      <div class="mt-4 flex w-full justify-center">
        <Button id="assignMarketBtn" variant="primary" datacy="assign-market-button" v-bind="$attrs">Assign Market</Button>
      </div>
    </DialogTrigger>
    <DialogContent @escape-key-down.prevent="" @pointer-down-outside.prevent="" @interact-outside.prevent="">
      <form @submit.prevent="handleSubmit">
        <DialogHeader>
          <DialogTitle>Choose Layer/Segment</DialogTitle>
        </DialogHeader>
        <rq-listbox-single
          v-model="state.selectedTower"
          id="selectTower"
          :items="program?.towers ?? []"
          text-field="name"
          value-field="id"
          label="Select Tower"
          :return-object="true"
          :errors="errors.selectedTower"
          datacy="tower-select"
        />
        <rq-listbox-single
          v-model="state.selectedLayer"
          id="selectLayer"
          :items="layerItems"
          text-field="name"
          value-field="id"
          label="Select Layer"
          :return-object="true"
          :errors="errors.selectedLayer"
          datacy="layer-select"
        />
        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-out"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <rq-listbox-single
            v-if="segmentItems.length > 0"
            v-model="state.selectedSegment"
            id="selectSegment"
            :items="segmentItems"
            text-field="name"
            value-field="id"
            label="Select Segment"
            :return-object="true"
            :errors="errors.selectedSegment"
            datacy="segment-select"
          />
        </transition>
        <DialogFooter class="pt-2">
          <Button id="addLayerBtn" variant="primary" datacy="addLayerButton" class="flex w-full items-center space-x-2" type="submit">
            <rq-icon icon="lucide:circle-plus" />
            <span>Select Markets to Assign to Selected Layer/Segment</span>
          </Button>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>

  <!--
      The following is the step 2 of the modal dialog to assign carriers to the selected tower, layer or segment.
    -->
  <ProgramIndicationAssignSelectCarrier
    v-model="selectCarrierDialog"
    :program="program"
    :selected-tower="state.selectedTower"
    :selected-layer="state.selectedLayer"
    :selected-segment="state.selectedSegment"
    @close-parent-dialog="closeDialog"
  />
</template>

<style lang="scss" scoped></style>
