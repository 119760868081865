<script setup lang="ts">
import type { PropType } from 'vue'
import { ref, watch } from 'vue'
import debounce from 'lodash/debounce'

import { cn } from '@/lib/utils'

import { amBestService } from '@/capability/ambest/service'
import type { AMBestCompanyModel } from '@/capability/ambest/types'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import { Command, CommandInput, CommandItem, CommandList } from '@/component/arqu-components/shadcn/ui/command'
import { Label } from '@/component/arqu-components/shadcn/ui/label'
import { Popover, PopoverContent, PopoverTrigger } from '@/component/arqu-components/shadcn/ui/popover'

const props = defineProps({
  modelValue: {
    type: Object as PropType<AMBestCompanyModel | undefined>,
    required: false,
    default: undefined
  },
  search: {
    type: String,
    required: false,
    default: ''
  }
})

const emit = defineEmits<{
  (e: 'update:modelValue', payload: AMBestCompanyModel): void
}>()

const open = ref<boolean>(false)
const search = ref<string>(props.search ?? '')
const items = ref<AMBestCompanyModel[]>([])

function handleSelect(amBestCompanyModel: AMBestCompanyModel) {
  emit('update:modelValue', amBestCompanyModel)
  open.value = false
}

const debouncedFetch = debounce(async (searchTerm: string) => {
  items.value = await amBestService.getAll(searchTerm)
  if (items.value.length == 1) {
    emit('update:modelValue', items.value[0])
  }
}, 500)

watch(
  () => props.search,
  async (value) => {
    if (value) {
      search.value = value
    }
  },
  { immediate: true }
)

watch(
  search,
  async () => {
    if (search.value) {
      debouncedFetch.cancel()
      await debouncedFetch(search.value)
    }
  },
  { immediate: true }
)
</script>

<template>
  <div>
    <Label>Or from the AM Best Companies List</Label>
    <Popover v-model:open="open">
      <PopoverTrigger as-child>
        <Button type="button" variant="trigger" role="combobox" :aria-expanded="open" :class="cn('flex w-full justify-between px-3')">
          <span v-if="modelValue">{{ modelValue.companyName }}</span>
          <span v-else class="text-gray-500">Select AM Best company</span>
          <rq-icon icon="lucide:chevron-down" class="text-gray-500" />
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start">
        <Command :model-value="modelValue" v-model:search-term="search">
          <CommandInput placeholder="Type to search..." />
          <CommandList>
            <CommandItem v-if="items.length > 100" value="none" disabled>Too many results. Please narrow your search</CommandItem>
            <CommandItem v-else-if="!!search && !items.length" value="none" disabled>
              Your search for "{{ search }}" returned no results.
            </CommandItem>
            <CommandItem v-for="item in items" :key="item.id as string" :value="item" @select="handleSelect(item)">
              <span>{{ item.companyName }}</span>
              <rq-icon icon="lucide:check" :class="cn('ml-auto', modelValue === item ? 'opacity-100' : 'opacity-0')" />
            </CommandItem>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  </div>
</template>
