import type { ComponentPublicInstance } from 'vue'
import { datadogRum } from '@datadog/browser-rum'

import { git } from '@/lib/utils/git-util.js'
import { isInternalUser } from '@/lib/utils/user'

import { getLogger } from '@/capability/log'
import type { UserModel } from '@/capability/user/model'
import type { FeatureDto } from 'typescript-core-api-client/dist/api'

import { rollbar } from './rollbar' // Import Rollbar instance

const logger = getLogger('ArquDatadog')

const env = import.meta.env.VITE_ARQU_ENVIRONMENT

const log = env === 'local' ? console.log : () => {}

// Global context to store configuration
let globalContext: Record<string, any> = {
  environment: _getDatadogEnv(env),
  version: git.version()
}

const TAG_NOTICE = '[ALERT:NOTICE]'
const TAG_FRICTION = '[ALERT:FRICTION]'

type PersonType = {
  id: string
  email: string
  username: string
  features: string[]
  isInternal: boolean
}

let activePerson: PersonType | null = null

// Rollbar blocklist
const rollbarBlocklist: string[] = [
  'Doc downloaded'
  // Add more blocked message patterns here
]

export const rqDatadog = {
  updateUser: (user: UserModel, features: FeatureDto[]) => {
    if (!datadogRum) {
      logger.error('Datadog RUM appears not to be initialized. Encountered when attempting to update Datadog with user info.')
      return
    }
    const enabledFeatures: string[] = features
      .filter((feature) => feature.enabled && !!feature.name)
      .map((feature) => feature.name) as string[]
    const datadogPerson: PersonType = {
      id: user.id || 'UNKNOWN',
      email: user.email || 'UNKNOWN',
      username: `${user.firstName} ${user.lastName}`,
      features: enabledFeatures,
      isInternal: isInternalUser(user)
    }
    rqDatadog.configure({
      payload: {
        person: datadogPerson
      }
    })
  },

  configure: (config: {
    payload?: {
      person?: PersonType
      context?: Record<string, any>
      client?: {
        javascript?: {
          code_version?: string
        }
      }
    }
  }) => {
    if (config.payload) {
      if (config.payload.person) {
        activePerson = config.payload.person
        datadogRum.setUser({
          id: config.payload.person.id,
          email: config.payload.person.email,
          name: config.payload.person.username,
          features: config.payload.person.features,
          isInternal: config.payload.person.isInternal
        })
      }

      if (config.payload.context) {
        globalContext = { ...globalContext, ...config.payload.context }
        datadogRum.setGlobalContext(globalContext)
      }

      if (config.payload.client?.javascript) {
        const jsConfig = config.payload.client.javascript
        if (jsConfig.code_version) {
          globalContext.version = jsConfig.code_version
          datadogRum.setGlobalContext(globalContext)
        }
      }
    }
  }
}

export class Alerting {
  notify(message: string, customData?: Record<string, any>) {
    log('Alerting.notify called with:', { message, customData })
    if (activePerson?.isInternal) {
      log('Skipping notify for internal user')
      return
    }
    this.overridePageIdIfProvided(customData)
    const preparedMessage = `${TAG_NOTICE} ${message}`
    const resolvedCustomData = this.resolveCustomData(preparedMessage, customData)

    const payload = {
      type: 'notice',
      message: preparedMessage,
      ...resolvedCustomData
    }

    try {
      const sanitizedPayload = this.sanitizePayload(payload)
      log('Sanitized payload:', sanitizedPayload)
      datadogRum.addAction(preparedMessage, sanitizedPayload)

      // Send to Rollbar if not in blocklist
      if (!this.isMessageBlocked(preparedMessage)) {
        rollbar.info(preparedMessage, sanitizedPayload)
        log('Notice sent successfully to Datadog and Rollbar')
      } else {
        log('Notice sent successfully to Datadog only (blocked for Rollbar)')
      }
    } catch (error) {
      console.error('Failed to send notice:', error)
    }
  }

  friction(message: string, context?: { error?: Error; customData?: Record<string, any> }) {
    log('Alerting.friction called with:', { message, context })
    this.overridePageIdIfProvided(context?.customData)
    const error = context?.error
    const preparedMessage = `${TAG_FRICTION} ${message}` + (error ? `. Error: ${error.message}` : '')
    const resolvedCustomData = this.resolveCustomData(preparedMessage, context?.customData)

    if (error) {
      const alertingError = new AlertingError(preparedMessage, error)
      const payload = {
        ...resolvedCustomData,
        errorType: 'friction'
      }

      try {
        const sanitizedPayload = this.sanitizePayload(payload)
        log('Sanitized payload:', sanitizedPayload)
        datadogRum.addError(alertingError, sanitizedPayload)

        // Send to Rollbar if not in blocklist
        if (!this.isMessageBlocked(preparedMessage)) {
          rollbar.error(alertingError, sanitizedPayload)
          log('Friction error sent successfully to Datadog and Rollbar')
        } else {
          log('Friction error sent successfully to Datadog only (blocked for Rollbar)')
        }
      } catch (error) {
        console.error('Failed to send friction error:', error)
      }
    } else {
      const payload = {
        type: 'friction',
        message: preparedMessage,
        ...resolvedCustomData
      }

      try {
        const sanitizedPayload = this.sanitizePayload(payload)
        log('Sanitized payload:', sanitizedPayload)
        datadogRum.addAction('friction', sanitizedPayload)

        // Send to Rollbar if not in blocklist
        if (!this.isMessageBlocked(preparedMessage)) {
          rollbar.warning(preparedMessage, sanitizedPayload)
          log('Friction action sent successfully to Datadog and Rollbar')
        } else {
          log('Friction action sent successfully to Datadog only (blocked for Rollbar)')
        }
      } catch (error) {
        console.error('Failed to send friction action:', error)
      }
    }
  }

  throwFriction(message: string, error: unknown, customData?: Record<string, any>) {
    log('Alerting.throwFriction called with:', { message, error, customData })
    const preparedMessage = `${TAG_FRICTION} ${message}. Error: ${(error as unknown as Error).message}`
    const resolvedCustomData = this.resolveCustomData(preparedMessage, customData)
    const alertingError = new AlertingError(preparedMessage, error as unknown as Error, resolvedCustomData)

    const payload = {
      ...resolvedCustomData,
      errorType: 'thrown_friction'
    }

    try {
      const sanitizedPayload = this.sanitizePayload(payload)
      log('Sanitized payload:', sanitizedPayload)
      datadogRum.addError(alertingError, sanitizedPayload)

      // Send to Rollbar if not in blocklist
      if (!this.isMessageBlocked(preparedMessage)) {
        rollbar.error(alertingError, sanitizedPayload)
        log('Thrown friction sent successfully to Datadog and Rollbar')
      } else {
        log('Thrown friction sent successfully to Datadog only (blocked for Rollbar)')
      }
    } catch (error) {
      console.error('Failed to send thrown friction:', error)
    }

    throw alertingError
  }

  private overridePageIdIfProvided(customData?: Record<string, any>) {
    if (customData?.pageId) {
      datadogRum.setGlobalContext({ ...globalContext, pageId: customData.pageId })
    }
  }

  private resolveCustomData(message: string, customData?: Record<string, any>): Record<string, any> {
    const actor = this.getAppropriateActor(customData)
    return this.sanitizePayload({ ...customData, actor, alertMessage: message })
  }

  private getAppropriateActor(customData?: Record<string, any>): string {
    if (activePerson) {
      return `${activePerson.username} (${activePerson.email})`
    }
    if (customData?.actor) {
      if (customData.userId) {
        return `${customData.actor} (userId: ${customData.userId})`
      }
      return `${customData.actor} (unauthenticated)`
    }
    return ''
  }

  private sanitizePayload(payload: any): any {
    if (Array.isArray(payload)) {
      return payload.map((item) => this.sanitizePayload(item))
    } else if (typeof payload === 'object' && payload !== null) {
      const sanitized: Record<string, any> = {}
      for (const [key, value] of Object.entries(payload)) {
        if (typeof key === 'string' && !key.match(/^\d+$/)) {
          sanitized[key] = this.sanitizePayload(value)
        }
      }
      return sanitized
    } else {
      return payload
    }
  }

  isMessageBlocked(message: string): boolean {
    return rollbarBlocklist.some((blockedText) => message.includes(blockedText))
  }
}

export class AlertingError extends Error {
  cause?: Error
  customData?: Record<string, any>

  constructor(message: string, error?: Error, customData?: Record<string, any>) {
    super(message)
    this.name = 'AlertingError'
    this.cause = error
    this.customData = customData
    Object.setPrototypeOf(this, new.target.prototype)

    if (error?.stack) {
      this.stack = `${this.stack}\nCaused by:\n${error.stack}`
    }
  }
}

function _getDatadogEnv(arquEnv: string): string {
  switch (arquEnv) {
    case 'prod':
      return 'production'
    default:
      return arquEnv
  }
}

export const alerting = new Alerting()

export function errorHandler(error: unknown, vm: ComponentPublicInstance | null, info: string) {
  const ignoreErrors = [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    'Error: Request aborted',
    'TypeError: Failed to fetch dynamically imported module:',
    '(unknown): "Object Not Found Matching id:'
  ]

  if (error instanceof Error && ignoreErrors.includes(error.message)) {
    return
  }

  if (error instanceof AlertingError) {
    datadogRum.addError(error, error.customData)
    if (!alerting.isMessageBlocked(error.message)) {
      rollbar.error(error, error.customData)
    }
    console.error(error)
    return
  }

  throw error
}

// Debugging: Log Datadog API calls
if (env === 'local') {
  const originalFetch = window.fetch
  window.fetch = function (...args) {
    const url = args[0]
    if (typeof url === 'string' && url.includes('datadoghq.com')) {
      log('Datadog API call:', url)
    }
    return originalFetch.apply(this, args)
  }
}
