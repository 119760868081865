import 'reflect-metadata'

import { Exclude, plainToClassFromExist, plainToInstance, Type } from 'class-transformer'
import cloneDeep from 'lodash.clonedeep'

import * as numberUtil from '@/lib/utils/formatting/number'

import { safeStringify } from '@/capability/json/jsonUtil'
import type { LayerModel } from '@/capability/layer/LayerModel'
import { LayerModelImpl } from '@/capability/layer/LayerModelImpl'
import type { LayerModelMutable } from '@/capability/layer/LayerModelMutable'
import { d4l, getLogger } from '@/capability/log'
import { resourceModelDefaultValueService } from '@/capability/resource/ResourceModelDefaultValueService'
import type { TowerDto } from '@/capability/tower/TowerDto'
import * as towerDtoUtil from '@/capability/tower/towerDtoUtil'
import type { TowerModel } from '@/capability/tower/TowerModel'
import type { TowerModelImmutable } from '@/capability/tower/TowerModelImmutable'
import type { TowerDtoPerilsEnum, TowerDtoProductEnum } from 'typescript-core-api-client/dist/api'
import { type CoverageDto } from 'typescript-core-api-client/dist/api'

import type { TowerModelConstructionAttr } from './TowerModelConstructionAttr'

const logger = getLogger('tower.TowerModelImpl')

/**
 * Lightweight Client-side model for a Tower
 */
export class TowerModelImpl implements TowerModel, TowerModelImmutable {
  'id'?: string
  'name'?: string
  'assigned'?: boolean
  'attachedTo'?: Array<string>
  'color'?: string
  'coverage'?: CoverageDto
  'groupId'?: string
  @Type(() => LayerModelImpl)
  'layers'?: Array<LayerModel>
  'perils'?: Array<TowerDtoPerilsEnum>
  'product'?: TowerDtoProductEnum

  constructor(attr: TowerModelConstructionAttr) {
    if (attr != null) {
      if (Object.prototype.hasOwnProperty.call(attr, '_towerDto')) {
        plainToClassFromExist(this, attr._towerDto)
      }
      if (Object.prototype.hasOwnProperty.call(attr, 'id')) {
        this.id = attr.id
      }
      if (Object.prototype.hasOwnProperty.call(attr, 'name')) {
        this.name = attr.name
      }
      if (Object.prototype.hasOwnProperty.call(attr, 'limit')) {
        towerDtoUtil.assureLimitPresent(this)
        this.coverage!.limit!.amount = attr.limit
      }
    } else {
      Object.assign(this, {})
    }

    resourceModelDefaultValueService.superimposeTowerDefaultsWhereUndefinedInPlace({ tower: this, recursively: true })
  }

  /**
   * Starts with an underscore.   Just pause a moment before using this.
   * @Deprecated
   */
  public _getTowerDtoMutableRef(): TowerDto {
    return this
  }

  public _getTowerDtoCopy(): TowerDto {
    return cloneDeep(this)
  }

  /**
   * The returned TowerDto is readonly reference
   * @Deprecated Readonly only does a shallow copy, we should use vue readonly instead.
   */
  public _getTowerDtoReadonlyRef(): Readonly<TowerDto> {
    // TODO: Is this safe?
    return this as Readonly<TowerDto>
  }

  @Exclude()
  public get limit(): number | undefined | null {
    return this.coverage?.limit?.amount
  }

  public set limit(value: number | undefined | null) {
    logger.debug(`set limit(): Entering with value = ${d4l(value)}`)
    value = numberUtil.tryToNumberIfPossible(value as unknown as string)
    towerDtoUtil.assureLimitPresent(this)
    this.coverage!.limit!.amount = value == null ? undefined : value
  }

  public set _limitWithNoopSetter(value: number | undefined | null) {
    // no-op
  }

  @Exclude()
  public get _limitWithNoopSetter(): number | undefined | null {
    return this.limit
  }

  @Exclude()
  public get excess(): number | undefined | null {
    return this.coverage?.excess?.amount
  }

  public set excess(value: number | undefined | null) {
    logger.debug(`set excess(): Entering with value = ${d4l(value)}`)
    value = numberUtil.tryToNumberIfPossible(value as unknown as string)
    towerDtoUtil.assureExcessPresent(this)
    this.coverage!.excess!.amount = value == null ? undefined : value
  }

  public getTop(): number /* float */ | undefined {
    if (this.excess != null && this.limit != null) {
      return this.limit + this.excess
    }
    return undefined
  }

  public generateJitArrayOfLayerModelMutable(): LayerModelMutable[] | undefined {
    return this.layers as LayerModelMutable[]
  }

  // public generateJitArrayOfLayerModelMutableTopMostFirst(): LayerModelMutable[] | undefined {
  //   if (this.mTowerDto.layers == null) {
  //     return this.mTowerDto.layers
  //   }
  //   return towerDtoUtil
  //     .tryGetSortedLayersTopMostFirst(this.mTowerDto)!
  //     .map((layerDto) => resourceModelFactory.buildLayerFromLayerDtoViaMoveSemantics(layerDto))
  // }

  public appendLayerInPlace(layerModelMutable: LayerModelMutable): void {
    this.layers = this.layers || []
    this.layers.push(plainToInstance(LayerModelImpl, layerModelMutable))
  }

  public getNumEmbeddedLayers(): number {
    if (this.layers == null) {
      return 0
    }
    return this.layers.length
  }

  public toHuman(): string {
    return safeStringify({
      id: this.id,
      limit: this.limit,
      excess: this.excess,
      numLayers: this.getNumEmbeddedLayers()
    })
  }

  /**
   * Complete State backing the TowerModel asJson.
   *
   * (Effectively) This `new TowerModelImpl( existingTowerModel.asJson() )`
   * should clone the state of a TowerModel
   */
  public asJson(): JSON {
    const retval: any = {}
    /**
     * The returned JSON will likely container _towerDto.   Do Not use '_towerDto' or anything within this JSON
     * unless you're sure you want to.   Wouldn't it be cool if someday _towerDto went away
     */
    if (this != null) {
      retval._towerDto = JSON.parse(safeStringify(this))
      // logger.trace(`asJson(): this.mTowerDto = ${d4l(this.mTowerDto)}`)
    }

    if (this.name != null) {
      retval.name = this.name
    }
    if (this.id != null) {
      retval.id = this.id
    }

    if (this.limit != null) {
      retval.limit = this.limit
    }

    return retval
  }

  public tryGetPlugLayerWhichIsAtTheTop(): LayerModelMutable | undefined {
    return towerDtoUtil.tryGetPlugLayerWhichIsAtTheTop(this._getTowerDtoMutableRef()) as LayerModelMutable
  }
}
