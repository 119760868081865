import Cookies from 'universal-cookie'

import { ACCESS_TOKEN_COOKIE_NAME, determineCookieDomainFromHostnameIfNeeded, USER_ID_COOKIE_NAME } from '@/capability/cookie/cookieUtil'
import { getLogger } from '@/capability/log'

const logger = getLogger('authentication/authenticationUtil.ts')

export function setAccessTokenCookie(value: string): void {
  const d = new Date()
  // 99 years expiry (matches v1)
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * 365 * 99)
  const options: { path: string; expires: Date; domain?: string } = {
    path: '/',
    expires: d
  }

  const useThisCookieDomain: undefined | string = determineCookieDomainFromHostnameIfNeeded(
    typeof window !== 'undefined' ? window?.location?.hostname : ''
  )

  if (typeof useThisCookieDomain === 'string' && useThisCookieDomain.length > 0) {
    options.domain = useThisCookieDomain
  }
  try {
    const cookie = new Cookies(null, options)
    cookie.set(ACCESS_TOKEN_COOKIE_NAME, value)
  } catch (err) {
    logger.error(`setAccessTokenCookie(): Error setting cookie.  ${err}.  (This is prob fine in mocha testing)`)
  }
}

export function deleteLegacyAccessTokenCookie(): void {
  const cookie = new Cookies(null)
  cookie.remove(ACCESS_TOKEN_COOKIE_NAME, { domain: '.arqu.co', path: '/' })
  cookie.remove(ACCESS_TOKEN_COOKIE_NAME, { domain: 'app.arqu.co', path: '/' })
}

export function deleteAccessTokenCookie(): void {
  const options: { path: string; domain?: string } = { path: '/' }
  const useThisCookieDomain: undefined | string = determineCookieDomainFromHostnameIfNeeded(
    typeof window !== 'undefined' ? window?.location?.hostname : ''
  )
  if (typeof useThisCookieDomain === 'string' && useThisCookieDomain.length > 0) {
    options.domain = useThisCookieDomain
  }
  const cookie = new Cookies(null, options)
  cookie.remove(ACCESS_TOKEN_COOKIE_NAME)
  if (!options.domain?.startsWith('.')) {
    cookie.remove(ACCESS_TOKEN_COOKIE_NAME, { ...options, domain: `.${options.domain}` })
  }
}

export function getAccessTokenCookieValue(): string | undefined {
  const options: { domain?: string } = {}
  const useThisCookieDomain: undefined | string = determineCookieDomainFromHostnameIfNeeded(
    typeof window !== 'undefined' ? window?.location?.hostname : ''
  )
  if (typeof useThisCookieDomain === 'string' && useThisCookieDomain.length > 0) {
    options.domain = useThisCookieDomain
  }
  const cookie = new Cookies(null, options)
  return cookie.get(ACCESS_TOKEN_COOKIE_NAME)
}

export function setUserIdCookie(userId: string): void {
  if (userId == null) {
    return
  }

  const hostname = window?.location?.hostname
  const maxAge = 60 * 60 * 24 * 365 * 10
  const options: { maxAge: number; domain?: string } = { maxAge }
  if (hostname.endsWith('.arqu.co')) {
    options.domain = '.arqu.co'
  }
  const cookie = new Cookies(null, options)
  cookie.set(USER_ID_COOKIE_NAME, userId)
}
