<script setup lang="ts">
import type { PropType } from 'vue'
import { ref } from 'vue'

import { getDocumentName } from '@/capability/document/util'
import type { DocumentDto } from 'typescript-core-api-client'

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/component/arqu-components/shadcn/ui/alert-dialog'
import { Button } from '@/component/arqu-components/shadcn/ui/button'

const props = defineProps({
  primaryEmailAddresses: {
    type: Array as PropType<String[]>,
    required: false,
    default: () => []
  },
  otherEmailAddresses: {
    type: Array as PropType<String[]>,
    required: false,
    default: () => []
  },
  documentIds: {
    type: Array as PropType<String[]>,
    required: false,
    default: () => []
  },
  attachmentIds: {
    type: Array as PropType<String[]>,
    required: false,
    default: () => []
  },
  documents: {
    type: Array as PropType<DocumentDto[]>,
    required: false,
    default: () => []
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  loading: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits<{
  (e: 'submit'): void
}>()

const open = ref<boolean>(false)

function handleSubmit() {
  emit('submit')
  open.value = false
}
</script>

<template>
  <AlertDialog v-model:open="open">
    <AlertDialogTrigger as-child>
      <Button type="button" variant="primary" :disabled="disabled || loading" id="success-btn" datacy="confirm-draft-button">
        Confirm
      </Button>
    </AlertDialogTrigger>
    <AlertDialogContent class="z-[1007]" @escape-key-down.prevent="" @pointer-down-outside.prevent="" @interact-outside.prevent="">
      <AlertDialogHeader>
        <AlertDialogTitle>Confirm Send Clearings</AlertDialogTitle>
        <AlertDialogDescription>Send the completed draft clearing</AlertDialogDescription>
      </AlertDialogHeader>
      <div class="max-h-[400px] space-y-4 overflow-y-auto text-left">
        <div>
          <span class="font-semibold">This will create a draft for the following recipients:</span>
          <ul class="divide-y">
            <li v-for="recipient in [...primaryEmailAddresses, ...otherEmailAddresses]" :key="recipient.toString()">
              {{ recipient }}
            </li>
          </ul>
        </div>
        <div v-if="attachmentIds?.length || documentIds?.length">
          <span class="font-semibold">The following documents will be provisioned and listed:</span>
          <div class="space-y-2">
            <div v-if="documentIds?.length">
              <h4 class="italic">Provisioned Documents</h4>
              <ul class="divide-y">
                <li v-for="id in documentIds" :key="id">
                  {{ getDocumentName(id, documents) }}
                </li>
              </ul>
            </div>
            <div v-if="attachmentIds?.length">
              <h4 class="italic">Attached Documents</h4>
              <ul class="divide-y">
                <li v-for="id in attachmentIds" :key="id">
                  {{ getDocumentName(id, documents) }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <AlertDialogFooter>
        <Button id="cancel-btn" type="button" :disabled="loading" variant="outline" @click="open = false">Cancel</Button>
        <Button id="success-btn" type="button" :loading="loading" variant="primary" datacy="confirm-draft-button" @click="handleSubmit">
          Confirm
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>

<style scoped lang="scss"></style>
