<script setup lang="ts">
import type { PropType } from 'vue'

import type { MessageDto } from 'typescript-core-api-client'

import ProgramMessageListItem from '@/component/program/messages/ProgramMessageListItem.vue'

const props = defineProps({
  messages: {
    type: Array as PropType<Array<MessageDto>>,
    required: false,
    default: () => []
  }
})
const emit = defineEmits<{
  (e: 'handleMessageSelect', id: string): void
}>()

const handleMessageSelect = (id: string) => emit('handleMessageSelect', id)
</script>

<template>
  <div v-if="!messages?.length" class="flex justify-center py-16 text-lg">No Messages</div>
  <div v-else class="max-h-[500px] overflow-y-auto">
    <ul class="divide-y">
      <li v-for="(message, idx) in messages" :key="idx">
        <ProgramMessageListItem :message="message" @handle-message-select="handleMessageSelect" />
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss"></style>
