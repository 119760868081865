<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useAcl } from 'vue-simple-acl/src'

import { useAIChat } from '@/composables/components/chat'
import type { PromisedResultType } from '@/lib/types'
import { promiseAllSettled } from '@/lib/utils/promises'
import { useLineItemsStore } from '@/stores/line-item'
import { useProgramStore } from '@/stores/program'
import { useTowerStore } from '@/stores/towers'

import type { DealModel } from '@/capability/deal/types/deal-model'
import type { ExposureModel } from '@/capability/exposure/ExposureModel'
import { exposureService } from '@/capability/exposure/ExposureService'
import type { LineItemModel } from '@/capability/line-item/model'
import { EXPOSURES } from '@/capability/program/constants'
import type { ProgramModel } from '@/capability/program/ProgramModel'
import { programService } from '@/capability/program/ProgramService'
import { assignOrderToUnorderedTowers } from '@/capability/tower/util'
import { DocumentChatRequestAgentTypeEnum } from 'typescript-core-api-client'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import AIChat from '@/component/chat/AIChat.vue'
import ProgramLeftSideBar from '@/component/program/ProgramLeftSideBar.vue'
import ProgramNavBarComponent from '@/component/program/ProgramNavBarComponent.vue'
import ProgramOptions from '@/component/program/ProgramOptions.vue'
import ProgramPanelAppBarComponent from '@/component/program/ProgramPanelAppBarComponent.vue'
import ProgramTitle from '@/component/program/ProgramTitle.vue'

const programStore = useProgramStore()
const towerStore = useTowerStore()
const lineItemsStore = useLineItemsStore()

const props = defineProps({
  deal: {
    type: Object as PropType<DealModel>,
    required: true
  }
})

const acl = useAcl()
const route = useRoute()

const { goToDocId, goToPageNumber, highlightKeywords } = useAIChat()

const dealId = route.params.dealId as string
const programId = route.params.programId as string

type ResultType = [ExposureModel[], ProgramModel, void?]
const promises: PromisedResultType<ResultType> = [
  exposureService.getByProgramId({ programId }),
  programService.readProgramById({ programId })
]
const [_exposures, program] = await promiseAllSettled<PromisedResultType<ResultType>, ResultType>(promises)

// Order the towers upon fetching from the programService
program.towers = assignOrderToUnorderedTowers(program.towers ?? [])
programStore.$patch({ name: program.title, program })

const exposuresModel = ref<ExposureModel[]>((_exposures?.length > 0 ? _exposures : []) as ExposureModel[])
exposuresModel.value!.sort((a, b) => {
  return EXPOSURES.findIndex((e) => e.name == a.name) - EXPOSURES.findIndex((e) => e.name == b.name)
})

const toggleVisibilityIcon = computed(() => (programStore.accordionsExpanded ? 'lucide:fold-vertical' : 'lucide:unfold-vertical'))
const toggleVisibilityText = computed(() => (programStore.accordionsExpanded ? 'Collapse All' : 'Expand All'))

window.addEventListener('beforeunload', (event) => {
  if (programStore.isProgramEditable) {
    event.preventDefault()
    return (event.returnValue = 'You may have unsaved changes. Are you sure you want to exit?')
  } else {
    return null
  }
})

const onAccordionToggleClicked = () => programStore.$patch({ accordionsExpanded: !programStore.accordionsExpanded })

const activeTowerName = computed(() => {
  const activeTower = (programStore.program?.towers ?? []).find((t) => t.id === towerStore.activeTowerId)

  if (towerStore.activeTowerId && activeTower) {
    return activeTower.name!
  }

  return ''
})

/**
 * Override the pageName using the view param from the query string. This is used in the detail view for carrier specifically
 */
const pageName = computed(() => {
  switch (route.query.view) {
    case 'ProgramStructure':
      return 'Specifications'
    case 'ProgramIndication':
      return 'Request | Indication'
    case 'ProgramQuote':
      return 'Bind Authorization'
    case 'ProgramBind':
      return 'Bind Order'
    default:
      return route.meta.pageName
  }
})

onUnmounted(() => {
  programStore.program = {} as ProgramModel
  lineItemsStore.lineItems = [] as LineItemModel[]
})
</script>

<template>
  <div class="mb-8 w-full">
    <ProgramNavBarComponent
      v-model:exposures-model="exposuresModel"
      :deal="deal"
      :program-model="programStore.program"
      :line-items="lineItemsStore.lineItems"
    />
    <div class="flex w-full" v-bind="$attrs">
      <div v-show="programStore.leftSideBarVisible" :class="programStore.rightSideBarVisible ? 'w-4/12' : 'w-full'" class="px-4">
        <Suspense>
          <template #default>
            <ProgramLeftSideBar v-model:exposures-model="exposuresModel" :deal="deal" :program-model="programStore.program" />
          </template>
          <template #fallback>
            <div class="flex justify-center">
              <rq-progress-circular indeterminate />
            </div>
          </template>
        </Suspense>
      </div>
      <div v-show="programStore.rightSideBarVisible" :class="programStore.leftSideBarVisible ? 'w-8/12' : 'w-full'" class="rounded-lg px-2">
        <ProgramPanelAppBarComponent class="sticky top-[127px] z-40 py-0 text-lg">
          {{ pageName }}
        </ProgramPanelAppBarComponent>
        <div class="sticky top-[157px] z-40 grid max-h-[66px] grid-cols-4 bg-white pt-3">
          <div class="col-span-3 flex items-center space-x-2 text-lg font-bold text-gray-500">
            <ProgramOptions v-if="acl.anyRole(['admin', 'rs'])" :program="programStore.program" />
            <div class="flex items-center space-x-3">
              <ProgramTitle :program="programStore.program" class="text-gray-800" :display-info-tooltip="true" />
              <template v-if="activeTowerName">
                <span>/</span>
                <span class="text-gray-400">{{ activeTowerName }}</span>
              </template>
            </div>
          </div>
          <div class="col-span-1 flex items-center justify-end">
            <Button
              variant="primary-outline"
              class="flex items-center space-x-2"
              datacy="collapse-expand-button"
              @click="onAccordionToggleClicked"
            >
              <rq-icon :icon="toggleVisibilityIcon" />
              <span>{{ toggleVisibilityText }}</span>
            </Button>
          </div>
        </div>
        <router-view ref="view" v-slot="{ Component }" :key="(route.params?.programId as string) ?? ''">
          <Suspense>
            <template #default>
              <component
                v-model:exposures-model="exposuresModel"
                :is="Component"
                :deal="deal"
                :program-model="programStore.program"
                :line-items="lineItemsStore.lineItems"
              />
            </template>
            <template #fallback>
              <div class="flex justify-center">
                <rq-progress-circular indeterminate />
              </div>
            </template>
          </Suspense>
        </router-view>
      </div>
    </div>
  </div>
  <AIChat
    v-if="acl.anyRole(['admin', 'rs'])"
    :agent-types="[
      DocumentChatRequestAgentTypeEnum.Qa,
      DocumentChatRequestAgentTypeEnum.KnowledgeBased,
      DocumentChatRequestAgentTypeEnum.General
    ]"
    :deal-id="deal.id"
    v-model:doc-id="goToDocId"
    v-model:page-number="goToPageNumber"
    v-model:keywords="highlightKeywords"
  />
</template>

<style scoped></style>
