<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import { useProgramStore } from '@/stores/program'

import { dealReadService } from '@/capability/deal/services/read'
import type { DealModel } from '@/capability/deal/types/deal-model'
import type { ExposureModel } from '@/capability/exposure/ExposureModel'
import type { ProgramModel } from '@/capability/program/ProgramModel'
import { generateUuid } from '@/capability/uuid/uuidUtil'
import type { MessageDto } from 'typescript-core-api-client'

import ProgramMessages from '@/component/program/messages/ProgramMessages.vue'
import ProgramSchematicComponent from '@/component/program/ProgramSchematicComponent.vue'

const programStore = useProgramStore()
const route = useRoute()

const isLoading = ref(false)

const props = defineProps({
  programModel: {
    type: Object as PropType<ProgramModel>,
    required: true
  },
  deal: {
    type: Object as PropType<DealModel>,
    required: true
  },
  exposuresModel: {
    type: Array as PropType<ExposureModel[]>,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'update:exposuresModel', payload: ExposureModel[]): void
}>()

const displayProgramSchematic = ref<boolean>(true)
const headerText = computed(() => (displayProgramSchematic.value ? 'Schematic' : 'Messages'))
const icon = computed(() => (displayProgramSchematic.value ? 'mdi:email-open' : 'mdi:chart-box'))
const tooltipText = computed(() => (displayProgramSchematic.value ? 'Messages' : 'Schematic'))
const _exposuresModel = computed({
  get() {
    return props.exposuresModel as ExposureModel[]
  },
  set(newValue) {
    emit('update:exposuresModel', newValue)
  }
})

const messages = ref<MessageDto[]>([])

watch(displayProgramSchematic, async (newValue) => {
  if (!newValue) {
    try {
      isLoading.value = true
      messages.value = await dealReadService.getDealMessages({ dealId: props.deal.id! })
      // Only want the messages to be populated with dummy messages when in development
      // @ts-ignore
      if (!messages.value.length && import.meta.env.DEV) {
        const today = new Date()
        const dealId: string = generateUuid()
        messages.value = [
          {
            body: `Art party etsy trust fund, jean shorts brunch man bun marfa. Paleo fam truffaut small batch succulents tofu crucifix disrupt iceland kombucha health goth semiotics. Kombucha unicorn echo park, street art freegan stumptown neutra trust fund. Poke kale chips bicycle rights quinoa, kinfolk single-origin coffee meggings pabst distillery letterpress vice DIY. Disrupt thundercats keytar, shabby chic crucifix vexillologist irony tilde flexitarian blog.

XOXO gentrify hella pug gluten-free. Pitchfork succulents polaroid, flannel schlitz woke sriracha neutra bespoke yr viral. Mumblecore retro trust fund messenger bag. Typewriter meggings pok pok prism migas leggings. Twee semiotics fingerstache, af pork belly blog irony ennui shaman offal ramps taxidermy mixtape chicharrones put a bird on it.

Fingerstache photo booth paleo polaroid. Lo-fi pok pok authentic kitsch austin hashtag meditation. PBR&B mustache scenester forage humblebrag pabst bitters. Food truck normcore bicycle rights man braid tacos occupy 8-bit hoodie chicharrones sartorial unicorn put a bird on it lyft cray gentrify. Master cleanse shoreditch lumbersexual readymade pinterest vice 90's fam before they sold out portland cornhole four loko heirloom. Bespoke sartorial tumblr, four dollar toast af heirloom poke gluten-free. Williamsburg street art four loko pour-over thundercats.

`,
            cc: undefined,
            date: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 6, 40, 0).toString(),
            dealId,
            from: `Merrile Burgett <merrile.burgett@example.com>`,
            headers: undefined,
            historyId: undefined,
            labelIds: undefined,
            messageId: generateUuid(),
            snippet: 'Happy Carriers',
            subject: 'Thanks, everything approved!',
            textBody: undefined,
            threadId: undefined,
            to: `bob@example.com`
          },
          {
            body: `Art party etsy trust fund, jean shorts brunch man bun marfa. Paleo fam truffaut small batch succulents tofu crucifix disrupt iceland kombucha health goth semiotics. Kombucha unicorn echo park, street art freegan stumptown neutra trust fund. Poke kale chips bicycle rights quinoa, kinfolk single-origin coffee meggings pabst distillery letterpress vice DIY. Disrupt thundercats keytar, shabby chic crucifix vexillologist irony tilde flexitarian blog.

XOXO gentrify hella pug gluten-free. Pitchfork succulents polaroid, flannel schlitz woke sriracha neutra bespoke yr viral. Mumblecore retro trust fund messenger bag. Typewriter meggings pok pok prism migas leggings. Twee semiotics fingerstache, af pork belly blog irony ennui shaman offal ramps taxidermy mixtape chicharrones put a bird on it.

Fingerstache photo booth paleo polaroid. Lo-fi pok pok authentic kitsch austin hashtag meditation. PBR&B mustache scenester forage humblebrag pabst bitters. Food truck normcore bicycle rights man braid tacos occupy 8-bit hoodie chicharrones sartorial unicorn put a bird on it lyft cray gentrify. Master cleanse shoreditch lumbersexual readymade pinterest vice 90's fam before they sold out portland cornhole four loko heirloom. Bespoke sartorial tumblr, four dollar toast af heirloom poke gluten-free. Williamsburg street art four loko pour-over thundercats.

`,
            cc: undefined,
            date: new Date(2021, 11, 27).toString(),
            dealId,
            from: `Merrile Burgett <merrile.burgett@example.com>`,
            headers: undefined,
            historyId: undefined,
            labelIds: undefined,
            messageId: generateUuid(),
            snippet: 'Happy Carriers',
            subject: 'Thanks, everything approved!',
            textBody: undefined,
            threadId: undefined,
            to: `bob@example.com`
          },
          {
            body: `Art party etsy trust fund, jean shorts brunch man bun marfa. Paleo fam truffaut small batch succulents tofu crucifix disrupt iceland kombucha health goth semiotics. Kombucha unicorn echo park, street art freegan stumptown neutra trust fund. Poke kale chips bicycle rights quinoa, kinfolk single-origin coffee meggings pabst distillery letterpress vice DIY. Disrupt thundercats keytar, shabby chic crucifix vexillologist irony tilde flexitarian blog.

XOXO gentrify hella pug gluten-free. Pitchfork succulents polaroid, flannel schlitz woke sriracha neutra bespoke yr viral. Mumblecore retro trust fund messenger bag. Typewriter meggings pok pok prism migas leggings. Twee semiotics fingerstache, af pork belly blog irony ennui shaman offal ramps taxidermy mixtape chicharrones put a bird on it.

Fingerstache photo booth paleo polaroid. Lo-fi pok pok authentic kitsch austin hashtag meditation. PBR&B mustache scenester forage humblebrag pabst bitters. Food truck normcore bicycle rights man braid tacos occupy 8-bit hoodie chicharrones sartorial unicorn put a bird on it lyft cray gentrify. Master cleanse shoreditch lumbersexual readymade pinterest vice 90's fam before they sold out portland cornhole four loko heirloom. Bespoke sartorial tumblr, four dollar toast af heirloom poke gluten-free. Williamsburg street art four loko pour-over thundercats.

`,
            cc: undefined,
            date: new Date(2021, 11, 27).toString(),
            dealId,
            from: `Merrile Burgett <merrile.burgett@example.com>`,
            headers: undefined,
            historyId: undefined,
            labelIds: undefined,
            messageId: generateUuid(),
            snippet: 'Happy Carriers',
            subject: 'Thanks, everything approved!',
            textBody: undefined,
            threadId: undefined,
            to: `bob@example.com`
          },
          {
            body: `Art party etsy trust fund, jean shorts brunch man bun marfa. Paleo fam truffaut small batch succulents tofu crucifix disrupt iceland kombucha health goth semiotics. Kombucha unicorn echo park, street art freegan stumptown neutra trust fund. Poke kale chips bicycle rights quinoa, kinfolk single-origin coffee meggings pabst distillery letterpress vice DIY. Disrupt thundercats keytar, shabby chic crucifix vexillologist irony tilde flexitarian blog.

XOXO gentrify hella pug gluten-free. Pitchfork succulents polaroid, flannel schlitz woke sriracha neutra bespoke yr viral. Mumblecore retro trust fund messenger bag. Typewriter meggings pok pok prism migas leggings. Twee semiotics fingerstache, af pork belly blog irony ennui shaman offal ramps taxidermy mixtape chicharrones put a bird on it.

Fingerstache photo booth paleo polaroid. Lo-fi pok pok authentic kitsch austin hashtag meditation. PBR&B mustache scenester forage humblebrag pabst bitters. Food truck normcore bicycle rights man braid tacos occupy 8-bit hoodie chicharrones sartorial unicorn put a bird on it lyft cray gentrify. Master cleanse shoreditch lumbersexual readymade pinterest vice 90's fam before they sold out portland cornhole four loko heirloom. Bespoke sartorial tumblr, four dollar toast af heirloom poke gluten-free. Williamsburg street art four loko pour-over thundercats.

`,
            cc: undefined,
            date: new Date(2021, 11, 27).toString(),
            dealId,
            from: `Merrile Burgett <merrile.burgett@example.com>`,
            headers: undefined,
            historyId: undefined,
            labelIds: undefined,
            messageId: generateUuid(),
            snippet: 'Happy Carriers',
            subject: 'Thanks, everything approved!',
            textBody: undefined,
            threadId: undefined,
            to: `bob@example.com`
          },
          {
            body: `Art party etsy trust fund, jean shorts brunch man bun marfa. Paleo fam truffaut small batch succulents tofu crucifix disrupt iceland kombucha health goth semiotics. Kombucha unicorn echo park, street art freegan stumptown neutra trust fund. Poke kale chips bicycle rights quinoa, kinfolk single-origin coffee meggings pabst distillery letterpress vice DIY. Disrupt thundercats keytar, shabby chic crucifix vexillologist irony tilde flexitarian blog.

XOXO gentrify hella pug gluten-free. Pitchfork succulents polaroid, flannel schlitz woke sriracha neutra bespoke yr viral. Mumblecore retro trust fund messenger bag. Typewriter meggings pok pok prism migas leggings. Twee semiotics fingerstache, af pork belly blog irony ennui shaman offal ramps taxidermy mixtape chicharrones put a bird on it.

Fingerstache photo booth paleo polaroid. Lo-fi pok pok authentic kitsch austin hashtag meditation. PBR&B mustache scenester forage humblebrag pabst bitters. Food truck normcore bicycle rights man braid tacos occupy 8-bit hoodie chicharrones sartorial unicorn put a bird on it lyft cray gentrify. Master cleanse shoreditch lumbersexual readymade pinterest vice 90's fam before they sold out portland cornhole four loko heirloom. Bespoke sartorial tumblr, four dollar toast af heirloom poke gluten-free. Williamsburg street art four loko pour-over thundercats.

`,
            cc: undefined,
            date: new Date(2021, 11, 27).toString(),
            dealId,
            from: `Merrile Burgett <merrile.burgett@example.com>`,
            headers: undefined,
            historyId: undefined,
            labelIds: undefined,
            messageId: generateUuid(),
            snippet: 'Happy Carriers',
            subject: 'Thanks, everything approved!',
            textBody: undefined,
            threadId: undefined,
            to: `bob@example.com`
          },
          {
            body: `Art party etsy trust fund, jean shorts brunch man bun marfa. Paleo fam truffaut small batch succulents tofu crucifix disrupt iceland kombucha health goth semiotics. Kombucha unicorn echo park, street art freegan stumptown neutra trust fund. Poke kale chips bicycle rights quinoa, kinfolk single-origin coffee meggings pabst distillery letterpress vice DIY. Disrupt thundercats keytar, shabby chic crucifix vexillologist irony tilde flexitarian blog.

XOXO gentrify hella pug gluten-free. Pitchfork succulents polaroid, flannel schlitz woke sriracha neutra bespoke yr viral. Mumblecore retro trust fund messenger bag. Typewriter meggings pok pok prism migas leggings. Twee semiotics fingerstache, af pork belly blog irony ennui shaman offal ramps taxidermy mixtape chicharrones put a bird on it.

Fingerstache photo booth paleo polaroid. Lo-fi pok pok authentic kitsch austin hashtag meditation. PBR&B mustache scenester forage humblebrag pabst bitters. Food truck normcore bicycle rights man braid tacos occupy 8-bit hoodie chicharrones sartorial unicorn put a bird on it lyft cray gentrify. Master cleanse shoreditch lumbersexual readymade pinterest vice 90's fam before they sold out portland cornhole four loko heirloom. Bespoke sartorial tumblr, four dollar toast af heirloom poke gluten-free. Williamsburg street art four loko pour-over thundercats.

`,
            cc: undefined,
            date: new Date(2021, 11, 27).toString(),
            dealId,
            from: `Merrile Burgett <merrile.burgett@example.com>`,
            headers: undefined,
            historyId: undefined,
            labelIds: undefined,
            messageId: generateUuid(),
            snippet: 'Happy Carriers',
            subject: 'Thanks, everything approved!',
            textBody: undefined,
            threadId: undefined,
            to: `bob@example.com`
          },
          {
            body: `Art party etsy trust fund, jean shorts brunch man bun marfa. Paleo fam truffaut small batch succulents tofu crucifix disrupt iceland kombucha health goth semiotics. Kombucha unicorn echo park, street art freegan stumptown neutra trust fund. Poke kale chips bicycle rights quinoa, kinfolk single-origin coffee meggings pabst distillery letterpress vice DIY. Disrupt thundercats keytar, shabby chic crucifix vexillologist irony tilde flexitarian blog.

XOXO gentrify hella pug gluten-free. Pitchfork succulents polaroid, flannel schlitz woke sriracha neutra bespoke yr viral. Mumblecore retro trust fund messenger bag. Typewriter meggings pok pok prism migas leggings. Twee semiotics fingerstache, af pork belly blog irony ennui shaman offal ramps taxidermy mixtape chicharrones put a bird on it.

Fingerstache photo booth paleo polaroid. Lo-fi pok pok authentic kitsch austin hashtag meditation. PBR&B mustache scenester forage humblebrag pabst bitters. Food truck normcore bicycle rights man braid tacos occupy 8-bit hoodie chicharrones sartorial unicorn put a bird on it lyft cray gentrify. Master cleanse shoreditch lumbersexual readymade pinterest vice 90's fam before they sold out portland cornhole four loko heirloom. Bespoke sartorial tumblr, four dollar toast af heirloom poke gluten-free. Williamsburg street art four loko pour-over thundercats.

`,
            cc: undefined,
            date: new Date(2021, 11, 27).toString(),
            dealId,
            from: `Merrile Burgett <merrile.burgett@example.com>`,
            headers: undefined,
            historyId: undefined,
            labelIds: undefined,
            messageId: generateUuid(),
            snippet: 'Happy Carriers',
            subject: 'Thanks, everything approved!',
            textBody: undefined,
            threadId: undefined,
            to: `bob@example.com`
          }
        ]
      }
    } finally {
      isLoading.value = false
    }
  }
})

// Methods
function handleClick() {
  programStore.visibleSideBar = programStore.visibleSideBar === 'both' ? 'left' : 'both'
}
</script>

<template>
  <rq-loading-overlay v-model="isLoading" />
  <div class="sticky top-[128px] rounded-lg border">
    <div class="h-[30px] w-full bg-section-header py-0 pl-5 pr-0">
      <div class="grid grid-cols-6 text-center text-sm">
        <span class="col-span-2">
          <rq-btn
            v-if="!displayProgramSchematic"
            class="hover:bg-gray-200"
            size="sm"
            variant="ghost"
            @click="displayProgramSchematic = true"
          >
            <rq-icon class="mr-2" color="gray-500" icon="mdi:arrow-left" />
            Schematics
          </rq-btn>
        </span>
        <span class="col-span-3 my-auto font-medium text-rq-black">{{ headerText }}</span>
        <span class="col-span-1 flex items-center justify-end">
          <rq-btn
            id="program-schematic-toggle-messages-schematic-button"
            icon="square"
            size="xs"
            variant="ghost-primary"
            @click="displayProgramSchematic = !displayProgramSchematic"
          >
            <rq-icon color="primary" :icon="icon" />
            <rq-tooltip :text="tooltipText" />
          </rq-btn>
          <rq-btn
            id="program-schematic-expand-button"
            class="hidden pr-1 pt-1 hover:bg-gray-200 md:inline"
            icon="square"
            size="xs"
            variant="ghost"
            @click="handleClick"
          >
            <rq-icon :icon="programStore.getIcon" />
            <rq-tooltip text="Toggle Full Width" />
          </rq-btn>
        </span>
      </div>
    </div>
    <div>
      <ProgramSchematicComponent
        v-show="displayProgramSchematic"
        v-model:exposures-model="_exposuresModel"
        :program-model="programModel as ProgramModel"
        :show-add-tower-button="route.meta.programShowAddTower"
        :show-assign-market-button="route.meta.programShowAssignMarket"
      />
      <ProgramMessages v-if="!displayProgramSchematic" :messages="messages" />
    </div>
    <!--      <ProgramMessageList v-else :messages="messages" />-->
  </div>
</template>
