import type { RouteLocationNormalized } from 'vue-router'
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import type { Ability } from 'vue-simple-acl'
import { useAcl } from 'vue-simple-acl/src'

import type { PageMetaType, PageViewType } from '@/lib/types/router'
import { rqDatadog } from '@/lib/utils/datadog'
import { setDatadogRumUser } from '@/lib/utils/user'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notification'

import { deleteAccessTokenCookie, getAccessTokenCookieValue } from '@/capability/authentication/authenticationUtil'
import { signinService } from '@/capability/authentication/SigninService'
import { buildLoginPageViewTemplate, CUSTOM_CRUD_DOES_ALREADY_EXIST, USER_SESSION_RESOURCE_TYPE } from '@/capability/event/eventUtil'
import { systemEventCaptureService } from '@/capability/event/SystemEventCaptureService'
import { SystemEventDto } from '@/capability/event/SystemEventDto'
import { getLogger } from '@/capability/log'
import { runtimeConfigService } from '@/capability/runtimeConfig/ConfigService'
import type { UserModel } from '@/capability/user/model'
import type { FeatureDto, WhoAmIResponse } from 'typescript-core-api-client/dist/api'

import AdminRoutes from './admin'
import AuthRoutes from './auth'
import BillingRoutes from './billing'
import BusinessIntelligenceRoutes from './business-intelligence'
import CarrierRoutes from './carrier'
import DocumentRoutes from './documents'
import OpenRoutes from './open'
import ProgramRoutes from './program'
import RetailerRoutes from './retailer'
import { getRiskAttributesRoutes } from './risk-attributes'

const logger = getLogger('router')

const title = 'arqu app'

export const routes: RouteRecordRaw[] = [
  ...AuthRoutes,
  {
    path: '/just-the-towers',
    component: () => import('@/component/tower/TowerSchematicComponent.vue')
  },
  {
    path: '/a2z',
    component: () => import('@/views/admin/AdminPageComponent.vue'),
    children: AdminRoutes,
    meta: {
      requiresAuth: true,
      access: {
        roles: ['rs', 'admin']
      }
    }
  },
  {
    path: '/',
    component: () => import('@/component/root/RootComponent.vue'),
    children: [
      BusinessIntelligenceRoutes,
      {
        path: '',
        redirect: () => {
          const authStore = useAuthStore()
          const acl = useAcl()
          if (authStore.defaultRsLanding === 'dashboard' && acl.anyRole(['rs', 'admin'])) {
            return { name: 'RSDashboardList' }
          }
          return { name: 'DealListView' }
        }
      },
      {
        path: '/rs-dashboard',
        name: 'RSDashboardList',
        component: () => import('@/views/rs-dashboard/RSDashboardListView.vue'),
        meta: {
          pageViewDetails: {
            pageId: 'rs-dashboard-list',
            title: 'RS Dashboard list'
          },
          requiresAuth: true,
          title: 'RS Dashboard',
          access: {
            roles: ['rs', 'admin']
          },
          class: 'h-auto'
        }
      },
      {
        path: '/deal-queue',
        name: 'DealQueueList',
        component: () => import('@/views/deal/deal-queue/DealQueueList.vue'),
        meta: {
          pageViewDetails: {
            pageId: 'deal-queue',
            title: 'Deal Queue'
          },
          requiresAuth: true,
          title: 'Deal Queue',
          access: {
            roles: ['rs', 'admin']
          }
        }
      },
      {
        path: '/deal-queue/:dealId',
        name: 'DealQueueDetail',
        component: () => import('@/views/deal/deal-queue/DealQueueDetail.vue'),
        meta: {
          pageViewDetails: {
            pageId: 'deal-queue-detail',
            title: 'Deal Queue Detail'
          },
          requiresAuth: true,
          title: 'Deal Queue Detail',
          access: {
            roles: ['rs', 'admin']
          }
        }
      },
      RetailerRoutes,
      {
        path: '/deals',
        children: [
          {
            path: '',
            component: () => import('@/views/deal/retailer/DealList.vue'),
            name: 'DealListView',
            meta: {
              pageViewDetails: {
                pageId: 'deal-list',
                resourceType: 'deal',
                resourceCrudl: 'list',
                title: 'Deal Hub'
              },
              requiresAuth: true,
              title: 'Deal Hub',
              access: {
                roles: ['admin', 'rs']
              }
            }
          },
          {
            path: 'new',
            component: () => import('@/views/deal/CreateNewDeal.vue'),
            name: 'DealCreateNew',
            meta: {
              pageViewDetails: {
                pageId: 'deal-create-new',
                resourceType: 'deal',
                resourceCrudl: 'new',
                title: 'Create New Deal'
              },
              requiresAuth: true,
              title: 'Create New Deal',
              access: {
                roles: ['rs', 'admin']
              }
            }
          },
          {
            path: 'conversion',
            component: () => import('@/views/deal/retailer/lego/DealHubLegoLayout.vue'),
            name: 'DealListViewLego',
            meta: {
              pageViewDetails: {
                pageId: 'deal-retailer-list-lego',
                resourceType: 'deal',
                resourceCrudl: 'list',
                title: 'Deal Hub Lego'
              },
              requiresAuth: true,
              roles: ['rs', 'admin']
            }
          },
          {
            path: 'retailer',
            redirect: { name: 'DealListView' }
          },
          {
            path: ':dealId',
            component: () => import('@/component/deal/DealIndividualSandboxComponent.vue'),
            children: [
              {
                path: '',
                alias: 'manager',
                component: () => import('@/views/deal/retailer/lego/DealManagerLegoLayout/DealManagerLegoLayout.vue'),
                name: 'DealDetailView',
                meta: {
                  ruleName: 'deal-read-info',
                  pageViewDetails: {
                    pageId: 'deal-read',
                    resourceType: 'deal',
                    resourceCrudl: 'read',
                    title: 'Deal Manager'
                  },
                  title: 'Deal Manager',
                  requiresAuth: true,
                  access: {
                    roles: ['admin', 'rs']
                  }
                }
              },
              {
                path: 'legacy',
                component: () => import('@/views/deal/retailer/DealDetail.vue'),
                name: 'DealDetailLegacy',
                meta: {
                  ruleName: 'deal-read-info',
                  pageViewDetails: {
                    pageId: 'deal-retailer-read-legacy',
                    resourceType: 'deal',
                    resourceCrudl: 'read',
                    title: 'Deal Manager',
                    requiresAuth: true
                  },
                  roles: ['rs', 'admin']
                },
                children: [
                  {
                    path: ':programId',
                    component: () => import('@/views/deal/retailer/DealDetailProgram.vue'),
                    name: 'DealProgramDetailLegacy',
                    meta: {
                      requiresAuth: true,
                      title: 'Deal Manager',
                      access: { roles: ['admin', 'rs'] }
                    }
                  }
                ]
              },
              ProgramRoutes,
              getRiskAttributesRoutes(),
              ...DocumentRoutes,
              {
                path: 'organization-fetch-links',
                component: () => import('@/component/organization/OrganizationFetchLinksComponent.vue'),
                name: 'OrganizationFetchLinks',
                meta: {
                  pageViewDetails: {
                    pageId: 'organization-fetch-links',
                    resourceType: 'organizationFetchLinks',
                    resourceCrudl: 'list'
                  },
                  requiresAuth: true,
                  title: 'Fetch Links'
                }
              },
              {
                path: 'messages',
                name: 'DealMessages',
                component: () => import('@/views/messages/DealMessages.vue'),
                meta: {
                  ruleName: 'messages-read',
                  pageViewDetails: {
                    pageId: 'deal-messages-list',
                    resourceType: 'deal-message',
                    resourceCrudl: 'list'
                  },
                  requiresAuth: true,
                  title: 'Deal Messages'
                }
              },
              {
                path: 'clearing-submission',
                name: 'ClearingAndSubmission',
                component: () => import('@/views/clearing-submission/ClearingAndSubmissionBase.vue'),
                children: [
                  {
                    path: 'draft',
                    name: 'ClearingAndSubmissionDraft',
                    component: () => import('@/views/clearing-submission/ClearingSubmissionDraft.vue'),
                    meta: {
                      requiresAuth: true,
                      title: 'Clearing and Submissions Draft'
                    }
                  },
                  {
                    path: '',
                    name: 'ClearingAndSubmissionListAndDetail',
                    component: () => import('@/views/clearing-submission/ClearingSubmissionListAndDetail.vue'),
                    meta: {
                      requiresAuth: true,
                      title: 'Clearing and Submissions'
                    }
                  }
                ],
                meta: {
                  requiresAuth: true
                }
              },
              BillingRoutes
            ]
          },
          {
            path: ':dealId/reviewSOV/:documentId',
            component: () => import('@/views/deal/StatementOfValuesReview.vue'),
            name: 'StatementOfValuesReview',
            meta: {
              pageViewDetails: {
                pageId: 'deal-sov-read',
                resourceType: 'deal-sov',
                resourceCrudl: 'read'
              },
              requiresAuth: true,
              title: 'Statement of Values'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/risk-narrative',
    redirect: { name: 'RiskNarrative-Open' },
    name: 'RiskNarrativeOpenView'
  },
  {
    path: '/carriers',
    redirect: { name: 'Documents-Open' },
    name: 'CarrierView'
  },
  {
    path: '/documents/preview/:docId',
    redirect: { name: 'DocumentPreview-Open' },
    name: 'CarrierDocumentPreview'
  },
  OpenRoutes,
  ...CarrierRoutes,
  {
    path: '/deals/:dealId/documents/preview/:docId',
    name: 'DocumentPreview',
    component: () => import('@/views/documents/DocumentPreview.vue'),
    meta: {
      ruleName: 'document-read',
      pageViewDetails: {
        pageId: 'deal-document-preview',
        resourceType: 'deal-document',
        resourceCrudl: 'preview'
      },
      title: 'Document Preview'
    }
  },
  {
    path: '/deals/:dealId/documents/spreadsheet-viewer/:docId',
    name: 'DocumentSpreadsheetViewer',
    component: () => import('@/views/documents/DocumentSpreadsheetViewer.vue'),
    meta: {
      ruleName: 'document-read',
      pageViewDetails: {
        pageId: 'deal-document-spreadsheet-viewer',
        resourceType: 'deal-document',
        resourceCrudl: 'spreadsheet-viewer'
      },
      title: 'Document Spreadsheet Viewer'
    }
  },
  {
    path: '/deals/:dealId/messages/preview/:messageId',
    name: 'MessagePreview',
    component: () => import('@/views/messages/DealMessagesPreview.vue'),
    meta: {
      ruleName: 'message-read',
      pageViewDetails: {
        pageId: 'deal-message-preview',
        resourceType: 'deal-message',
        resourceCrudl: 'preview'
      },
      title: 'Message Preview'
    }
  },
  {
    path: '/deals/:dealId/map',
    component: () => import('@/views/deal/DealGoogleMapStandaloneComponent.vue'),
    name: 'DealGoogleMapStandaloneView',
    meta: {
      pageViewDetails: {
        pageId: 'deal-map',
        resourceType: 'deal-map',
        resourceCrudl: 'read'
      },
      requiresAuth: false,
      title: 'Deal Map'
    }
  },
  {
    path: '/dynamic-dashboard',
    component: () => import('@/views/dynamic-dashboard/DynamicDashboardBase.vue'),
    name: 'DynamicDashboardBase',
    meta: {
      requiresAuth: true,
      access: {
        roles: ['rs', 'admin']
      },
      title: 'Dynamic Dashboard'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/four-oh-four.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach(async (to) => {
  const meta: PageMetaType = (to.meta ?? {}) as PageMetaType
  if (meta.pageViewDetails != null) {
    const details = meta.pageViewDetails as PageViewType
    const event = new SystemEventDto()
    systemEventCaptureService.fireAndForgetPageView({ ...event, ...details } as SystemEventDto)
  }

  if (meta?.title) {
    document.title = `${meta.title} | ${title}`
  } else {
    document.title = title
  }
})

async function routerBeforeEach(to: RouteLocationNormalized) {
  const authStore = useAuthStore()
  const notificationStore = useNotificationStore()
  const acl = useAcl()

  /**********************************************************/

  const meta: PageMetaType = (to.meta ?? {}) as PageMetaType
  const pageId = meta.pageViewDetails?.pageId
  if (pageId) {
    rqDatadog.configure({
      payload: {
        context: pageId
      }
    })
  }

  // // If the retailer experience toggle is set to true then clicking "Leave Retailer Experience" won't actually do
  // // anything so we need to add a query parameter to tell the store to disable the retailer experience and then after
  // // navigating there remove the query parameter
  // if (to.query.disableRetailerExperience) {
  //   authStore._retailerView = 'false'
  //   delete to.query.disableRetailerExperience
  //   return to
  // }
  if (to.fullPath.includes('/deal/')) {
    return `/deals${to.fullPath.substring(5)}`
  }

  // fix legacy path from email
  if (to.fullPath.includes('/riskAttributes')) {
    return to.fullPath
      .replace('/riskAttributes', '/risk-attributes')
      .replace('Risk%20Snapshot', 'risk-snapshot')
      .replace('Risk+Snapshot', 'risk-snapshot')
      .replace('Risk Snapshot', 'risk-snapshot')
  }

  const cookieAccessToken = getAccessTokenCookieValue()

  if (!authStore.user && !cookieAccessToken && meta.requiresAuth !== false) {
    const query = { ...to.query, ...{ redirectUrl: authStore.getRedirectUrl(to) } }
    if (to.query.token) {
      return { name: 'ResetPassword', query: query }
    } else if (to.query.recipientOrgId || to.query.message) {
      const passwordlessAuthFeature = await runtimeConfigService.getFeatureFlag({ flag: 'passwordless-auth' })
      if (passwordlessAuthFeature) {
        return { name: 'Login', query: query }
      } else {
        return { name: 'Onboarding', query: query }
      }
    } else {
      return { name: 'Login', query: { redirectUrl: authStore.getRedirectUrl(to) } }
    }
  }

  // initialize the user access token and object
  if (cookieAccessToken && authStore.accessToken !== cookieAccessToken) {
    authStore.accessToken = cookieAccessToken
    try {
      const response: WhoAmIResponse = await signinService.whoami()
      const { user, privileges, features, refreshToken } = extractUserInfo(response)
      updateStore(user, privileges, features, refreshToken)
      rqDatadog.updateUser(user, features)
      setDatadogRumUser(user)

      // poll for the latest user object every 15 minutes
      setInterval(
        () => {
          signinService
            .whoami()
            .then((response: WhoAmIResponse) => {
              const { user, privileges, features, refreshToken } = extractUserInfo(response)
              updateStore(user, privileges, features, refreshToken)
            })
            .catch((e) => {
              logger.error('whoami error: ', e)
            })
        },
        1000 * 60 * 15
      )
    } catch (e) {
      if (to.name === 'VerifyLogin') {
        // no-ops since we want to stay in this view
      } else {
        logger.error('whoami error: ', e)
        if (e.response?.status >= 400 && e.response?.status <= 499) {
          deleteAccessTokenCookie()
          systemEventCaptureService.fireAndForgetProgrammaticEvent({
            pageId: 'router-index',
            code: 'access-token-invalid',
            resourceType: 'user-session',
            resourceCrudl: 'delete'
          })
        }

        return authStore.getRedirectUrl(to)
      }
    }

    // subscribe to access token change
    authStore.$subscribe((mutation) => {
      if (mutation.type === 'direct' && mutation.storeId === 'auth' && mutation.events?.key === 'accessToken') {
        signinService.whoami().then((response: WhoAmIResponse) => {
          const { user, privileges, features, refreshToken } = extractUserInfo(response)
          updateStore(user, privileges, features, refreshToken)
          rqDatadog.updateUser(user, features)
        })
      }
    })
  }

  if (
    !!to.name &&
    ['RiskNarrative-Open', 'Documents-Open', 'DocumentPreview-Open', 'RiskStatementOfValues-Open'].includes(to.name as string)
  ) {
    return true
  }
  if (!!to.name && to.name === 'RiskStatementOfValues-Carrier' && !!authStore.user?.id && !acl.anyRole(['carrier'])) {
    return { name: 'RiskStatementOfValues', params: to.params }
  }
  /****************** LOCK DOWN CARRIER XP ******************/
  if (acl.anyRole(['carrier'])) {
    if (
      !to.name ||
      ![
        'CarrierDocumentPreview',
        'DocumentPreview-Carrier',
        'DocumentPreview-Open',
        'CarrierView',
        'Documents-Carrier',
        'Documents-Open',
        'RiskNarrative-Open',
        'RiskNarrative-Carrier',
        'DealListView-Carrier',
        'RiskSnapshot',
        'RiskStatementOfValues',
        'RiskStatementOfValues-Carrier',
        'RiskStatementOfValues-Open',
        'LoginLanding',
        'VerifyLogin'
      ].includes(to.name as string)
    ) {
      return { name: 'DealListView-Carrier' }
    }
    if (!!to.name && to.name.includes('-Carrier')) {
      return true
    }
    try {
      if (!to.name) {
        throw new Error(`No route name found. Route location is ${to.fullPath}`)
      }
      const baseName = to.name.split('-')[0]
      if (baseName === 'NotFound') return true
      const carrierName = `${baseName}-Carrier`
      return { ...to, name: carrierName }
    } catch (err) {
      console.error(err)
      notificationStore.publishWarningMessage('There was an error attempting to go to that route.')
      return { name: 'DealListView-Carrier' }
    }
  }

  if (acl.anyRole(['retailer'])) {
    if (!!to.name && to.name.includes('-Retailer')) {
      return true
    }
    try {
      if (!to.name) {
        throw new Error(`No route name found. Route location is ${to.fullPath}`)
      }
      const baseName = to.name.split('-')[0]
      if (baseName === 'NotFound') return true
      const retailerName = `${baseName}-Retailer`
      return { ...to, name: retailerName }
    } catch (err) {
      console.error(err)
      notificationStore.publishWarningMessage('There was an error attempting to go to that route.')
      return { name: 'DealListView-Retailer' }
    }
  }

  if (acl.anyRole(['admin', 'rs'])) {
    if (!!to.name && (to.name.includes('-Retailer') || to.name.includes('-Carrier'))) {
      try {
        if (!to.name) {
          throw new Error(`No route name found. Route location is ${to.fullPath}`)
        }
        return { ...to, name: to.name.split('-')[0] }
      } catch (err) {
        console.error(err)
        notificationStore.publishWarningMessage('There was an error attempting to go to that route.')
        return { name: 'DealListView' }
      }
    }
    if (!!to.name && ['DealDocuments', 'DealDocumentsList'].includes(to.name)) {
      if (authStore.newDocRepoPreferred && to.name === 'DealDocuments') {
        return { ...to, name: 'DealDocumentsList' }
      } else if (!authStore.newDocRepoPreferred && to.name === 'DealDocumentsList') {
        return { ...to, name: 'DealDocuments' }
      }
    }
  }

  const _meta = to.meta as PageMetaType
  if (_meta?.access && ((_meta.access.roles ?? []) as Ability[]).length > 0) {
    if (!acl.anyRole(_meta.access.roles as Ability[])) {
      if (_meta.access.notification) {
        notificationStore.publishByType(_meta.access.notification)
      } else {
        notificationStore.publishErrorMessage('You are not authorized to be there')
      }
      if (_meta.access.route) {
        return _meta.access.route
      } else {
        return '/'
      }
    }
  }

  if (to.name === 'RSDashboardList' && !authStore.rsDashboardEnabled) {
    notificationStore.publishWarningMessage('That area is not yet available.')
    return '/'
  }

  // check if user is a carrier and going to the summary view. If so, redirect them to the detailed view
  // const summaryViews = [
  //   'ProgramLandingComponent',
  //   'ProgramStructure',
  //   'ProgramIndication',
  //   'ProgramScenarios',
  //   'ProgramQuote',
  //   'ProgramBind'
  // ]
  // if (acl.anyRole(['carrier']) && to.name && summaryViews.includes(to.name.toString())) {
  //   const orgId = authStore.userOrganizationId
  //   return { name: 'programMarketDetailed', params: { ...to.params, ...{ marketId: orgId } }, query: { ...to.query, ...{ view: to.name } } }
  // }

  // We need to forward to the redirect url even user doesn't have access. This will allow users to
  // auto provision to the Deal if his org has access to it already.

  // check for program access if specified in the route
  // const ruleName = meta.ruleName as string
  // const redirectTo = (meta.redirectTo as string) || 'DealListView'
  // if (ruleName) {
  //   if (redirectTo == 'ProgramLandingComponent') {
  //     if (acl.notCan(ruleName, [{ id: `${to.params.programId}` }])) {
  //       return {
  //         name: redirectTo,
  //         params: to.params
  //       }
  //     }
  //   } else if (acl.notCan(ruleName, [{ id: `${to.params.dealId}` }])) {
  //     return {
  //       name: redirectTo,
  //       params: to.params
  //     }
  //   }
  // }
  if (meta.loggedInRedirect && authStore.user) {
    const email = (to.query?.email ?? '') as string
    systemEventCaptureService.fireAndForgetProgrammaticEvent({
      ...buildLoginPageViewTemplate(email),
      legacyEventName: 'Login-AlreadyLoggedIn',
      resourceType: USER_SESSION_RESOURCE_TYPE,
      resourceCrudl: CUSTOM_CRUD_DOES_ALREADY_EXIST,
      resourceCrudlResult: 'true',
      resourceInstanceName: email,
      resourceId: authStore.user?.id,
      snapshotUserBlurb: [authStore.user?.id, authStore.user?.email, email].join(', '),
      code: 'login-view-redirect'
    })
    const redirectUrl = authStore.getRedirectUrl()
    authStore.redirectUrl = ''
    return redirectUrl
  }

  function extractUserInfo(response: WhoAmIResponse) {
    const user = response.user
    const privileges = response.privileges || ([] as string[])
    const features = response.features || ([] as FeatureDto[])
    const refreshToken = response.refreshToken || ''
    return { user, privileges, features, refreshToken }
  }

  function updateStore(user: UserModel, privileges: string[], features: FeatureDto[], refreshToken: string) {
    authStore.$patch({
      user,
      privileges,
      features,
      refreshToken
    })
  }
}

router.beforeEach(routerBeforeEach)

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('Importing a module script failed')) {
    // @ts-ignore
    window.location = to.fullPath
  }
})

export default router
