<script setup lang="ts">
import type { PropType } from 'vue'
import { computed } from 'vue'

import type { MessageDto } from 'typescript-core-api-client'

const props = defineProps({
  message: {
    type: Object as PropType<MessageDto>,
    required: true
  },
  truncate: {
    type: Boolean,
    required: false,
    default: true
  },
  hideSnippet: {
    type: Boolean,
    required: false,
    default: false
  }
})

const fromName = computed(() => props.message?.from ?? '')
const toNames = computed(() => props.message?.to ?? '')
const ccNames = computed(() => props.message?.cc ?? '')
const truncateClass = computed(() => (props.truncate ? 'text-truncate' : ''))

const initials = computed(() => {
  const names = fromName.value.split(' ')
  if (names.length === 1) return names[0][0]
  return `${names[0][0]}${names[names.length - 1][0]}`
})
const subject = computed(() => props.message?.subject ?? '')
const snippet = computed(() => props.message?.snippet ?? '')
</script>

<template>
  <div class="flex">
    <rq-avatar class="mr-2 shrink-0" :user-name="fromName" />
    <div :class="`${truncateClass} flex flex-col`">
      <div class="text-xs">
        <span class="text-gray-400">From:</span>
        {{ fromName }}
      </div>
      <div class="text-xs">
        <span class="text-gray-400">To:</span>
        {{ toNames }}
      </div>
      <div class="text-xs">
        <span class="text-gray-400">Cc:</span>
        {{ ccNames }}
      </div>
      <div class="text-xs font-medium">
        <span class="text-gray-400">Subject:</span>
        {{ subject }}
      </div>
      <div v-if="!props.hideSnippet" class="mt-2">
        <span class="text-xs font-light italic">{{ snippet }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
