<script setup lang="ts">
import type { PropType } from 'vue'
import { computed } from 'vue'
import { ref, watch } from 'vue'
import { Buffer } from 'buffer'
import fileDownload from 'js-file-download'

import { useNotificationStore } from '@/stores/notification'

import type { IndicationMarketModel } from '@/capability/indication/model'
import { indicationService } from '@/capability/indication/service'
import type { ProgramModel } from '@/capability/program/ProgramModel'
import { CreateQuoteLetterRequestExportFormatEnum } from 'typescript-core-api-client/dist/api'

import { Button } from '@/component/arqu-components/shadcn/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/component/arqu-components/shadcn/ui/dialog'
import { Label } from '@/component/arqu-components/shadcn/ui/label'
import { RadioGroup, RadioGroupItem } from '@/component/arqu-components/shadcn/ui/radio-group'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/component/arqu-components/shadcn/ui/tooltip'
import ProgramIndicationIssuingCompanyDialog from '@/component/program/program-indications/program-indications-menu/ProgramIndicationIssuingCompanyDialog.vue'

const props = defineProps({
  program: {
    type: Object as PropType<ProgramModel>,
    required: true
  },
  fileName: {
    type: String,
    required: true
  }
})

const notificationStore = useNotificationStore()

const markets = ref<IndicationMarketModel[]>([])

const loading = ref<boolean>(false)
const selectedTower = ref<string>('')
const selectedMarketIds = ref<string[]>([])
const selectedFileFormat = ref<CreateQuoteLetterRequestExportFormatEnum>(CreateQuoteLetterRequestExportFormatEnum.Pdf)
const open = ref<boolean>(false)

async function handleGenerateQuote() {
  try {
    loading.value = true
    const content = await indicationService.generateQuoteLetter({
      dealId: props.program.dealId!,
      programId: props.program.id!,
      towerId: selectedTower.value,
      marketIds: selectedMarketIds.value,
      exportFormat: selectedFileFormat.value as CreateQuoteLetterRequestExportFormatEnum
    })
    const buf = Buffer.from(content, 'base64')
    fileDownload(buf, `${props.fileName}.${getFileExtension()}`)
    notificationStore.publishSuccessMessage('Quote Letter generated successfully')
  } catch (e: any) {
    notificationStore.publishOneOrMoreErrUnhandled(e.message)
  } finally {
    loading.value = false
    open.value = false
    resetDialog()
  }
}

function getFileExtension() {
  return selectedFileFormat.value == CreateQuoteLetterRequestExportFormatEnum.Excel ? 'xlsx' : 'pdf'
}

function resetDialog() {
  selectedTower.value = ''
  selectedMarketIds.value = []
  selectedFileFormat.value = CreateQuoteLetterRequestExportFormatEnum.Pdf
}

const marketWithNoIssuingCompany = computed(() =>
  markets.value.filter((market) => selectedMarketIds.value.includes(market.marketId!) && !market.issuingCompanyName)
)

watch(open, async (isOpen) => {
  if (!isOpen) {
    resetDialog()
  } else {
    const marketPairsModel = await indicationService.getIndicationMarketsPairsByProgramIds({ programIds: [props.program.id!] })
    markets.value = marketPairsModel?.indicationMarkets?.filter((market) => market.createdByUserType == 'Carrier') || []
  }
})

async function handleClosed() {
  const marketPairsModel = await indicationService.getIndicationMarketsPairsByProgramIds({ programIds: [props.program.id!] })
  markets.value = marketPairsModel?.indicationMarkets?.filter((market) => market.createdByUserType == 'Carrier') || []
}
</script>

<template>
  <TooltipProvider :delay-duration="100">
    <Tooltip>
      <TooltipTrigger>
        <Dialog v-model:open="open">
          <DialogTrigger as-child>
            <Button id="programs-navbar-quote-letter-button" variant="ghost-primary" size="lg" icon="square">
              <rq-icon icon="lucide:mail" />
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Generate a Quote Letter</DialogTitle>
              <DialogDescription>Select a Tower and Markets</DialogDescription>
            </DialogHeader>
            <div class="space-y-2">
              <rq-listbox-single
                v-model="selectedTower"
                label="Select Tower"
                id="tower-select"
                :items="program.towers"
                value-field="id"
                text-field="name"
                truncate-length="48"
              />
              <rq-combobox-multi
                v-model="selectedMarketIds"
                :items="markets"
                text-field="marketName"
                value-field="marketId"
                multiple
                label="Select Markets"
                truncate-length="48"
              />
              <ProgramIndicationIssuingCompanyDialog
                v-for="market in marketWithNoIssuingCompany"
                :key="market.marketId"
                :market="market"
                location="dialog"
                @closed="handleClosed"
              />
              <div class="space-y-1">
                <Label>Select File Format</Label>
                <RadioGroup v-model="selectedFileFormat" orientation="horizontal" class="flex items-center space-x-2">
                  <div class="flex items-center space-x-2">
                    <RadioGroupItem id="pdf" value="PDF" />
                    <Label for="pdf">PDF</Label>
                  </div>
                  <div class="flex items-center space-x-2">
                    <RadioGroupItem id="excel" value="Excel" />
                    <Label for="xlsx">Excel</Label>
                  </div>
                </RadioGroup>
              </div>
              <DialogFooter class="pt-4">
                <Button variant="primary" :loading="loading" @click="handleGenerateQuote">Generate Quote Letter</Button>
              </DialogFooter>
            </div>
          </DialogContent>
        </Dialog>
      </TooltipTrigger>
      <TooltipContent>Generate Quote Letter</TooltipContent>
    </Tooltip>
  </TooltipProvider>
</template>

<style scoped></style>
