<script setup lang="ts">
import type { PropType } from 'vue'
import { ref } from 'vue'

import { messagesService } from '@/capability/messages/service'
import type { MessageDto } from 'typescript-core-api-client'

import ProgramMessageDetail from '@/component/program/messages/ProgramMessageDetail.vue'
import ProgramMessageList from '@/component/program/messages/ProgramMessageList.vue'

const props = defineProps({
  messages: {
    type: Array as PropType<MessageDto[]>,
    required: true
  }
})

const activeMessage = ref<MessageDto>()

async function handleMessageSelect(messageId: string) {
  const response = await messagesService.getMessage({ messageId })
  activeMessage.value = response.message
}

function clearActiveMessage() {
  activeMessage.value = undefined
}
</script>

<template>
  <ProgramMessageDetail v-if="activeMessage" :message="activeMessage" @go-back="clearActiveMessage" />
  <ProgramMessageList v-else :messages="props.messages" @handle-message-select="handleMessageSelect" />
</template>

<style scoped lang="scss"></style>
