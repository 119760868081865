<script setup lang="ts">
import { useProgramStore } from '@/stores/program'
const programStore = useProgramStore()

const handleClick = () => (programStore.visibleSideBar = programStore.visibleSideBar === 'both' ? 'right' : 'both')
</script>

<template>
  <div class="h-[30px] bg-section-header px-0">
    <div class="grid text-center text-sm">
      <span class="col-span-3 col-start-2 my-auto font-medium text-rq-black">
        <slot />
      </span>
      <rq-btn
        icon="square"
        variant="ghost"
        size="xs"
        class="col-span-1 col-start-5 ml-auto pr-1 pt-1 hover:bg-gray-200"
        @click="handleClick"
      >
        <rq-icon size="medium" :icon="programStore.getIcon" />
        <rq-tooltip text="Toggle Full Width" />
      </rq-btn>
    </div>
  </div>
</template>
