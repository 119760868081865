<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, ref } from 'vue'

import type { StyledLayer } from '@/lib/types/tower-svg'
import { currencyFormatter, percentFormatter } from '@/lib/utils/formatting'
import { useTowerStore } from '@/stores/towers'

import type { LayerModel } from '@/capability/layer/LayerModel'
import { towerColors } from '@/capability/tower/util'

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/component/arqu-components/shadcn/ui/card'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/component/arqu-components/shadcn/ui/hover-card'
import SegmentDiagram from '@/component/segment/SegmentDiagram.vue'

const props = defineProps({
  layer: {
    type: Object as PropType<StyledLayer>,
    required: true
  },
  towerId: {
    type: String,
    required: true
  },
  towerName: {
    type: String,
    required: true
  },
  canInteract: {
    type: Boolean,
    required: true
  },
  level: {
    type: Number,
    required: true
  }
})

const towerStore = useTowerStore()

const menu = ref<boolean>(false)
function updateOpen(e: boolean) {
  if (!e || (e && props.canInteract)) {
    menu.value = e
  }
}

const hasSegments = (layer: LayerModel): boolean => (layer.layers ?? []).length > 0

const layerMax = computed(() => +(props.layer?.layer?.excess ?? '0') + +(props.layer?.layer?.limit ?? '0'))

const selectLayer = (scroll: boolean = true): void => {
  if (props.canInteract) {
    if (scroll) {
      towerStore.$patch({
        activeTowerId: props.towerId,
        activeLayerId: isClickable.value ? (props.layer!.layer.id ?? '') : ''
      })
      scrollToLayer()
    } else {
      towerStore.$patch({ activeTowerId: props.towerId })
    }
  }
}

const isActiveLayer = computed(() => towerStore.isActiveLayer(props.layer!.layer.id!))
const colorScheme = computed(() => towerColors[towerStore.isActiveTower(props.towerId as string) ? 'active' : 'inactive'])

const isClickable = computed(() => !props.layer!.layer.plug && props.canInteract)

const dynamicClass = computed(() => `${isActiveLayer.value ? 'selected' : ''} ${isClickable.value ? 'cursor-pointer' : ''}`)

function scrollToLayer(): void {
  const element = document.getElementById(`layer-target-edit-${props.layer!.layer.id}`)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

const participation = computed(() => percentFormatter('en-US', 0, 0).format((+props.layer.layer.layerTarget?.participation! ?? 0) / 100))
const layerPremium = computed(() => currencyFormatter().format(+props.layer.layer.layerTarget?.premium! ?? 0))
</script>

<template>
  <HoverCard :open="menu" :open-delay="0" @update:open="updateOpen">
    <HoverCardTrigger as-child>
      <div :style="layer.style" class="layer" :class="dynamicClass" @click="selectLayer">
        <div v-if="level < 2" class="layer-title">
          <span class="layer-title__box" :class="level === 1 ? 'text-2xs' : 'text-sm'">
            {{ layer.layer.getNameWithDefault() }}
          </span>
        </div>
        <SegmentDiagram
          v-if="hasSegments(layer.layer)"
          :raw-segments="layer.layer.layers as LayerModel[]"
          :layer-id="towerId"
          :layer-name="towerName"
          :layer-max="layerMax"
          :layer-min="layer.layer.excess as number"
          :level="level"
          @select-layer="selectLayer"
        />
      </div>
    </HoverCardTrigger>
    <HoverCardContent side="right" align="start" class="border-none bg-transparent shadow-none">
      <Card class="w-80 rounded-xl border border-primary-200">
        <CardHeader>
          <CardTitle>
            {{ towerName }}
          </CardTitle>
          <CardDescription>
            {{ layer.layer.getNameWithDefault() }}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div class="flex justify-between py-1">
            <span class="font-semibold text-gray-500">Participation</span>
            <span>{{ participation }} Covered</span>
          </div>
          <div class="flex justify-between py-1">
            <span class="font-semibold text-gray-500">Layer Premium</span>
            <span>{{ layerPremium }}</span>
          </div>
        </CardContent>
      </Card>
    </HoverCardContent>
  </HoverCard>
</template>

<style scoped lang="scss">
.layer {
  background-color: v-bind('colorScheme.background');
  color: #fff;
  font-weight: bold;
  border-color: #fff;
  border-style: solid;
  border-width: 2px 0 2px 1px;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  &:first-child {
    border-bottom-width: 0;
  }
  &:last-child {
    border-top-width: 0;
  }

  &.selected {
    background-color: v-bind('colorScheme.activeLayerBackground') !important;
  }

  .layer-title {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 50%;
    z-index: 1;
    .layer-title__box {
      padding: 0.5rem 1rem;
      background-color: v-bind('colorScheme.nameBackground');
      border-radius: 5px;
    }
  }
}
</style>
