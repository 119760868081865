import { plainToClassFromExist } from 'class-transformer'

import type { LineItemDto, LineItemQualifierDtoQualifierTypeEnum, LineItemTargetDto } from 'typescript-core-api-client'
import type {
  LineItemQualifierDto,
  LineItemQualifierDtoLimitTypeEnum,
  LineItemStatusDto,
  MoneyDto
} from 'typescript-core-api-client/dist/api'

export interface LineItemTargetModel extends LineItemTargetDto {}

export class LineItemTargetModelImpl implements LineItemTargetModel {
  constructor(attr: LineItemTargetDto) {
    if (attr != null) {
      plainToClassFromExist(this, attr)
    } else {
      Object.assign(this, {})
    }
  }
}

export type LineItemModel = LineItemDto

export class LineItemQualifierModel implements LineItemQualifierDto {
  'annualAggregate'?: boolean
  'limitAmount'?: string
  'limitAmountValue'?: number
  'limitType'?: LineItemQualifierDtoLimitTypeEnum
  'maxDeductible'?: MoneyDto
  'minDeductible'?: MoneyDto
  'previousLimitAmount'?: number
  'qualifierType'?: LineItemQualifierDtoQualifierTypeEnum
  'status'?: LineItemStatusDto

  constructor(limitType: LineItemQualifierDtoLimitTypeEnum) {
    this.limitType = limitType
  }
}
