export enum LogLvlEnum {
  debug = 'debug',
  trace = 'trace'
}

export class Logger {
  private readonly mName: string

  public constructor(name: string) {
    this.mName = name
  }

  // TODO:  These are still compile-time for the time being.   I gotta
  // figure out the cleanest way to make these run-time
  private forceDisableAllLogging(): boolean {
    return import.meta.env.VITE_FORCE_DISABLE_ALL_LOGGING === 'true'
  }

  private isLogTraceEnabled(): boolean {
    return true
  }
  private isLogDebugEnabled(): boolean {
    return true
  }

  public isTrace(): boolean {
    if (this.forceDisableAllLogging()) {
      return false
    }

    return this.isLogTraceEnabled()
  }

  public isDebug(): boolean {
    const defaultFlag: boolean = true

    if (this.forceDisableAllLogging()) {
      return false
    }

    return this.isLogDebugEnabled()
  }

  public isInfo(): boolean {
    const defaultFlag: boolean = true

    if (this.forceDisableAllLogging()) {
      return false
    }

    return defaultFlag
  }

  public isNotice(): boolean {
    const defaultFlag: boolean = true

    if (this.forceDisableAllLogging()) {
      return false
    }

    return defaultFlag
  }

  public isWarn(): boolean {
    const defaultFlag: boolean = true

    if (this.forceDisableAllLogging()) {
      return false
    }

    return defaultFlag
  }

  public isError(): boolean {
    const defaultFlag: boolean = true

    if (this.forceDisableAllLogging()) {
      return false
    }

    return defaultFlag
  }

  public ifTrace(func: (lvl: LogLvlEnum) => void) {
    if (this.isTrace()) {
      func(LogLvlEnum.trace)
    }
  }

  public ifDebug(func: (lvl: LogLvlEnum) => void) {
    if (this.isDebug()) {
      func(LogLvlEnum.debug)
    }
  }

  /**
   * w is for 'write'.   Or whatever.  I don't care what this is called.  Short seems ideal
   *
   * @param lvl
   * @param arg0
   * @param more
   */
  public w(lvl: LogLvlEnum, arg0: any, ...more: any[]) {
    if (lvl === LogLvlEnum.debug) {
      this.debug(arg0, ...more)
      return
    }
    if (lvl === LogLvlEnum.trace) {
      this.trace(arg0, ...more)
    }
  }

  public trace(arg0: any, ...more: any[]): void {
    if (this.isTrace()) {
      if (more.length == 0) {
        console.log(`[TRACE ] ${this.mName}   ${arg0}`)
      } else if (more.length > 0) {
        console.log(`[TRACE ] ${this.mName}   ${arg0}`, more)
      }
    }
  }

  public debug(arg0: any, ...more: any[]): void {
    if (this.isDebug()) {
      if (more.length == 0) {
        console.log(`[DEBUG ] ${this.mName}   ${arg0}`)
      } else if (more.length > 0) {
        console.log(`[DEBUG ] ${this.mName}   ${arg0}`, more)
      }
    }
  }

  public info(arg0: any, ...more: any[]): void {
    if (this.isInfo()) {
      if (more.length == 0) {
        console.log(`[INFO  ] ${this.mName}   ${arg0}`)
      } else if (more.length > 0) {
        console.log(`[INFO  ] ${this.mName}   ${arg0}`, more)
      }
    }
  }

  public notice(arg0: any, ...more: any[]): void {
    if (this.isNotice()) {
      if (more.length == 0) {
        console.info(`[NOTICE] ${this.mName}   ${arg0}`)
      } else if (more.length > 0) {
        console.info(`[NOTICE] ${this.mName}   ${arg0}`, more)
      }
    }
  }

  public warn(arg0: any, ...more: any[]): void {
    if (this.isWarn()) {
      if (more.length == 0) {
        console.warn(`[WARN  ] ${this.mName}   ${arg0}`)
      } else if (more.length > 0) {
        console.warn(`[WARN  ] ${this.mName}   ${arg0}`, more)
      }
    }
  }

  public error(arg0: any, ...more: any[]): void {
    if (this.isError()) {
      if (more.length == 0) {
        console.error(`[ERROR ] ${this.mName}   ${arg0}`)
      } else if (more.length > 0) {
        console.error(`[ERROR ] ${this.mName}   ${arg0}`, more)
      }
    }
  }
}

export class LoggerFactory {
  private static readonly mLoggers: Map<string, Logger> = new Map()

  public static getLogger(name: string): Logger {
    if (!this.mLoggers.has(name)) {
      const logger: Logger = new Logger(name)
      this.mLoggers.set(name, logger)
    }

    return this.mLoggers.get(name) as Logger
  }
}

export const getLogger = (loggerName: string) => {
  return LoggerFactory.getLogger(loggerName)
}
