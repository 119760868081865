<script setup lang="ts">
import { computed } from 'vue'
import cloneDeep from 'lodash.clonedeep'

import { useTowerStore } from '@/stores/towers'

import type { LayerModel } from '@/capability/layer/LayerModel'
import type { TowerModel } from '@/capability/tower/TowerModel'
import { getTowerCoverageLimit, towerColors } from '@/capability/tower/util'

import TowerDiagram from '@/component/tower/TowerDiagram.vue'
import TowerSchematicScale from '@/component/tower/TowerSchematicScale.vue'

const props = defineProps<{ towers: Array<TowerModel> }>()
const emit = defineEmits<{
  (e: 'update:towers', payload: TowerModel[]): void
}>()

const MIN_TOWER_SCALE = 0.15

const towerStore = useTowerStore()

// const towersMin = computed(() => Math.min(...props.towers.map((e) => +e.coverage?.excess?.amount!)))
const towersMax = computed(() => Math.max(...props.towers.map((e) => +e.coverage?.limit?.amount!)))
const lowestMax = computed(() => Math.min(...props.towers.map((e) => +e.coverage?.limit?.amount!)))
const rescale = computed(() => lowestMax.value / towersMax.value < MIN_TOWER_SCALE)

towerStore.$patch({ activeTowerId: props.towers[0]?.id || '' })

const updateTower = (id: string, layers: LayerModel[]): void => {
  const tower = cloneDeep(props.towers.find((e: TowerModel) => e.id === id) ?? ({} as TowerModel))
  tower.layers = layers
  emit(
    'update:towers',
    props.towers.map((e) => (e.id !== tower.id ? e : tower))
  )
}

const getScaledMax = (tower: TowerModel): number => {
  if (!rescale.value) return getTowerCoverageLimit(tower) / towersMax.value

  // https://stackoverflow.com/a/5295202
  const b = 1
  const a = MIN_TOWER_SCALE
  const min = lowestMax.value / towersMax.value
  const max = 1
  const x = getTowerCoverageLimit(tower) / towersMax.value
  return ((b - a) * (x - min)) / (max - min) + a
}

const scaleHeights = computed(() => props.towers.map((e) => ({ value: getTowerCoverageLimit(e), height: getScaledMax(e) })))
</script>

<template>
  <div v-if="props.towers && props.towers.length" class="flex h-[50vh] overflow-x-auto py-8">
    <TowerSchematicScale :scale-heights="scaleHeights" />
    <TowerDiagram
      v-for="(tower, idx) in props.towers"
      :key="tower.id"
      :layers="tower.layers"
      :tower-max="getTowerCoverageLimit(tower)"
      :tower-id="tower.id"
      :tower-name="tower.name"
      :base-color="towerColors[idx]"
      :scaled-tower-max="getScaledMax(tower)"
      @update:layers="updateTower"
    />
  </div>
  <div v-else class="min-h-[400px]">
    <h3 class="text-center text-gray-600">No towers added yet.</h3>
  </div>
</template>

<style scoped lang="scss"></style>
