import 'reflect-metadata'

import { createApp } from 'vue'
import JsonViewer from 'vue3-json-viewer'
import Vue3Tour from 'vue3-tour'
import { datadogRum } from '@datadog/browser-rum'
import { createPinia } from 'pinia'
import CodeEditor from 'simple-code-editor/CodeEditor.vue'
import { createHead } from 'unhead'

import { errorHandler, rqDatadog } from '@/lib/utils/datadog'

import acl from './plugins/acl'
import App from './App.vue'
import router from './router'

import './style.css'
import 'vue3-tour/dist/vue3-tour.css'
import 'vue3-json-viewer/dist/index.css'
// import 'vue-cesium/dist/index.css'
import 'simple-code-editor/themes/themes.css'
import 'simple-code-editor/themes/themes-base16.css'

import { registerDependencyInjection } from '@/plugins/dependency-injection'
import { globalClickTracker } from '@/plugins/global-click-tracker'
import { registerGlobalComponents } from '@/plugins/global-components'

const pinia = createPinia()
const head = createHead()

const app = createApp(App).use(pinia).use(router).use(acl).use(Vue3Tour).use(JsonViewer).use(head).component('code-editor', CodeEditor)

const env = import.meta.env.VITE_ARQU_ENVIRONMENT
const dataDogRUMBuildVersion = import.meta.env.VITE_DATADOG_RUM_BUILD_VERSION ?? 'defaultVersion'

if (env === 'prod' || env === 'staging' || env === 'development') {
  app.config.errorHandler = errorHandler
  app.config.globalProperties.$rollbar = rqDatadog
}

// only in prod and local
if (import.meta.env.VITE_DATADOG_RUM_ENABLED === 'true') {
  const datadog_applicationKey = import.meta.env.VITE_DATADOG_RUM_APPLICATION_ID
  const datadog_clientToken = import.meta.env.VITE_DATADOG_RUM_CLIENT_TOKEN
  datadogRum.init({
    applicationId: datadog_applicationKey,
    clientToken: datadog_clientToken,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'arqu-web',
    env: env,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: dataDogRUMBuildVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: [(url) => url.startsWith('https://api.arqu.co')],
    defaultPrivacyLevel: 'allow'
  })
}

// @ts-ignore
window.tours = app.config.globalProperties.$tours

registerGlobalComponents(app)
registerDependencyInjection(app)
app.use(globalClickTracker)
app.mount('#app')
