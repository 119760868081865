<script setup lang="ts">
import type { PropType } from 'vue'

import { formatDateForMessagesList } from '@/lib/utils/formatting'

import type { MessageDto } from 'typescript-core-api-client'

import ProgramMessageContentSummary from '@/component/program/messages/ProgramMessageContentSummary.vue'

const props = defineProps({
  message: {
    type: Object as PropType<MessageDto>,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'handleMessageSelect', id: string): void
}>()

const date = formatDateForMessagesList(new Date(props.message!.date!))

const handleOptionsClick = () => alert('options clicked')
const handleMessageSelect = () => emit('handleMessageSelect', props.message!.messageId!)
</script>

<template>
  <div class="grid cursor-pointer grid-cols-3 gap-2 px-3 py-2 hover:bg-primary-100" @click="handleMessageSelect">
    <ProgramMessageContentSummary :message="message" :truncate="true" class="col-span-2" />
    <!--    <rq-col cols="1" align-self="start">-->
    <!--      <rq-icon v-if="!props.message.read" size="small" color="gray-400" icon="mdi:email-outline" />-->
    <!--    </rq-col>-->
    <div class="col-span-1 flex flex-col items-end">
      <div class="text-sm text-gray-500">
        {{ date }}
      </div>
      <rq-btn icon variant="ghost" size="sm" @click.stop="handleOptionsClick">
        <rq-icon size="small" icon="mdi:dots-horizontal" />
      </rq-btn>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
