<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { Primitive, type PrimitiveProps } from 'radix-vue'

import { cn } from '@/lib/utils'

import { type ButtonVariants, buttonVariants } from '.'

import { RQIcon as RqIcon } from '@/component/arqu-components/icon'

interface Props extends PrimitiveProps {
  variant?: ButtonVariants['variant']
  size?: ButtonVariants['size']
  icon?: boolean | 'square' | 'rounded'
  class?: HTMLAttributes['class']
  as?: string
  loading?: boolean
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'default',
  size: 'default',
  icon: false,
  class: '',
  as: 'button',
  loading: false,
  disabled: false
})
</script>

<template>
  <Primitive
    :as="as"
    :as-child="asChild"
    :class="cn(buttonVariants({ variant, size, icon }), props.class)"
    :style="$attrs.style"
    :disabled="loading || disabled"
  >
    <rq-icon v-if="loading" icon="lucide:loader-circle" class="mr-2 animate-spin" />
    <slot />
  </Primitive>
</template>
