<script setup lang="ts">
import { useNotificationStore } from '@/stores/notification'

import { organizationService } from '@/capability/organization/OrganizationService'
import type { PromptModel } from '@/capability/prompt/PromptModel'
import { promptService } from '@/capability/prompt/PromptService'
import { DocumentChatRequestAgentTypeEnum } from 'typescript-core-api-client'
import { PromptDtoLineOfBusinessEnum } from 'typescript-core-api-client/dist/api'

import PromptInput from '@/component/prompt/PromptInput.vue'

const props = defineProps({
  dealId: {
    type: String,
    required: true
  },
  promptText: {
    type: String,
    default: ''
  },
  agentType: {
    type: String,
    default: DocumentChatRequestAgentTypeEnum.Qa
  }
})

const emit = defineEmits<{
  (e: 'update:create', payload: PromptModel): void
}>()

const notificationStore = useNotificationStore()

const organizations = await organizationService.getAll()

const EMPTY_PROMPT: PromptModel = {
  name: '',
  documentType: '',
  extractionType: '',
  lineOfBusiness: PromptDtoLineOfBusinessEnum.Unknown,
  organizationId: '',
  order: 0,
  prompt: '',
  description: '',
  documentAgentType: props.agentType
} as PromptModel

async function handleSubmit(prompt: PromptModel) {
  try {
    const newPrompt = await promptService.createPrompt({ prompt })
    notificationStore.publishSuccessMessage('Prompt created successfully')
    emit('update:create', newPrompt)
  } catch (e) {
    notificationStore.publishOneOrMoreErrUnhandled(e)
  }
}
</script>

<template>
  <PromptInput mode="create" :organizations="organizations" :prompt="EMPTY_PROMPT" @submit="handleSubmit" />
</template>
