<script setup lang="ts">
import type { PropType } from 'vue'

import { formatDateForMessageDetail } from '@/lib/utils/formatting'

import type { MessageDto } from 'typescript-core-api-client'

import ProgramMessageContentSummary from '@/component/program/messages/ProgramMessageContentSummary.vue'

const props = defineProps({
  message: {
    type: Object as PropType<MessageDto>,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'goBack'): void
}>()

const date = formatDateForMessageDetail(new Date(props.message!.date!))

const handleOptionsClick = () => alert('options clicked')
</script>

<template>
  <div class="p-3">
    <div class="mb-3 flex items-center justify-between">
      <rq-btn variant="ghost" prepend-icon="mdi:inbox" @click="emit('goBack')">
        <rq-icon icon="mdi:inbox" class="mr-2" />
        Inbox
      </rq-btn>
      <span class="text-end text-xs text-gray-400">{{ date }}</span>
    </div>
    <div class="grid grid-cols-11 items-start border-b border-gray-400 pb-2">
      <ProgramMessageContentSummary :message="message" :truncate="false" :hide-snippet="true" class="col-span-10" />
      <rq-btn variant="ghost" icon class="col-span-1" @click="handleOptionsClick">
        <rq-icon icon="mdi:dots-horizontal" />
      </rq-btn>
    </div>
    <div class="mt-1 max-h-[800px] overflow-y-auto" v-html="message.body"></div>
  </div>
</template>

<style scoped lang="scss"></style>
